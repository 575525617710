import React, { Fragment } from 'react';
import Layout from '../../components/LayoutFunnel';
import cx from 'classnames';
import Styles from '../../assets/styles/funnel.module.css'
import './choose-postcode.css'
import SecuredappDesktop from "../../assets/images/securedapp-section-img.png";
import SecuredappMobile from "../../assets/images/securedapp-section-mob-img.png";
import flagIcon from '../../assets/images/flagIcon.png'
// import icon1 from '../../assets/images/icon-1.png'
// import icon2 from '../../assets/images/icon-2.png'
// import icon3 from '../../assets/images/icon-3.png'
// import blank from '../../assets/images/blank.gif'
// import brokerImage from '../../assets/images/broker-img.png'
// import goldBrokerImage from '../../assets/images/gold-broker.png'
import tickImage from '../../assets/images/tick-img.png'
import logo from '../../assets/images/logo.png'
// import icon9 from '../../assets/images/icon9.png'
// import icon10 from '../../assets/images/icon10.png'
// import icon11 from '../../assets/images/icon11.png'
// import icon12 from '../../assets/images/icon12.png'
// import locationImg from '../../assets/images/locationImg.png'
import validator from 'validator';
import DealList from "../../components/deals/dealList";
import Axios from "axios";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealDetailSection from '../../components/deals/dealDetailSection'
import classes from '../../assets/styles/autoSearchBroker.module.css';
import BrokerListing from '../../components/brokers/brokerListing'
import BrokerDetailSection from '../../components/brokers/brokerDetailSection';
import BookAppointmentSidebar from './bookAppointmentSidebar';
import Cookies from 'universal-cookie';
import { navigate } from "gatsby"
import BorrowingCalculator from "../../components/borrowing-calculator";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { graphql } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import allKenticoCloudItemLeadSCount from "../../../data/kentico/allKenticoCloudItemLeadSCount";

library.add(faCheck)


const log = (logVar = '', separator = '-------') => {
    return false;
    console.log(separator)
    console.log(logVar)
    console.log(separator)
}

const AddClassInBody = () => {

    if (typeof document != 'undefined') {

        if (document.getElementsByTagName('body')
            && typeof document.getElementsByTagName('body') == 'object') {
            let body = document.getElementsByTagName('body')[0];
            body.classList.add("funnel-page-b-cl");
        }
    }
}
class FunnelApp extends React.Component {

    constructor(props) {

        super(props);
        this.loanAmount = allKenticoCloudItemLeadSCount.edges[0].node

        this.state = {
            steps: {
                current: 'get-started'
            },
            funnelData: {
                broker: null,
                deal: null,
                lead_type: null,
                postcode: null,
                suburb: null,
                state: null
            },
            utmTags: {},
            referrerKey: '',
            postcodeId: '',
            flowStartedFrom: null,
            saveProgressEmail: null,
            flow: null, // to check for lead or broker flow
            prevStep: null,
            stepsFollowed: [

            ],
            FunnelStepsDeals: [
                {
                    className: 'active',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Your rates'
                },
                {
                    className: '',
                    labelText: 'Your broker'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsBrokers: [
                {
                    className: 'active',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Your broker'
                },
                {
                    className: '',
                    labelText: 'Your rates'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectBrokers: [
                {
                    className: 'active',
                    labelText: 'Your rates'
                },
                {
                    className: '',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Your broker'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectDeals: [
                {
                    className: 'active',
                    labelText: 'Your broker'
                },
                {
                    className: '',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Your rates'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectBoth: [
                {
                    className: 'active',
                    labelText: 'Your rates'
                },
                {
                    className: '',
                    labelText: 'Your broker'
                },
                {
                    className: '',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ]
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleSaveProgress = this.handleSaveProgress.bind(this);
        this.dealClickHandler = this.dealClickHandler.bind(this);
        this.backBtnHandler = this.backBtnHandler.bind(this);
        this.handleBrokerClick = this.handleBrokerClick.bind(this);
        this.skipBtnHandler = this.skipBtnHandler.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.handleBookAppointmentData = this.handleBookAppointmentData.bind(this);
        this.handleBrokerContactMe = this.handleBrokerContactMe.bind(this);
        this.handleCalculatorClick = this.handleCalculatorClick.bind(this);
        this.handleSidebarClick = this.handleSidebarClick.bind(this);
        this.handleSaveYourProgress = this.handleSaveYourProgress.bind(this);
        this.cookieSet('flow', 'get-started');
    }

    getSteps() {
        let flow = this.cookieGet('flow');
        log(flow)
        let steps = this.state.FunnelSteps;
        let stateFlow = this.state.flow;

        if (flow == 'get-started' && stateFlow == 'deals') {
            return this.state.FunnelStepsDeals;
        } else if (flow == 'get-started') {
            return this.state.FunnelStepsBrokers;
        }

        if (flow == 'broker') {
            return this.state.FunnelStepsDirectBrokers;
        }

        if (flow == 'deals') {
            return this.state.FunnelStepsDirectDeals;
        }

        if (flow == 'both') {
            return this.state.FunnelStepsDirectDeals;
        }
    }

    cookieSet(variable, value = '') {
        let cookies = new Cookies();
        cookies.set(variable, value, { path: '/' });
        return cookies.get(variable)
    }

    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

    handleSaveYourProgress(email) {
        if (email) {
            this.cookieSet('SaveEmail', email)
        }
        if (this.state.stepsFollowed.indexOf('property-info-brokers') != -1) {
            this.handleClick('deal-listing');
        } else {
            this.handleClick('enter-postcode');
        }

        // log('hello');
    }

    backBtnHandler() {
        let state = this.state
        let steps = state.steps;
        let stepsFollowed = state.stepsFollowed;
        let currentStep = state.steps.current;
        // log(state.steps.current);
        // log(stepsFollowed);
        // log(stepsFollowed.indexOf(currentStep));

        if (state.steps.current == 'calculator') {
            let gotToPrevStep = this.state.gotToPrevStep
            this.setState({
                steps: { ...steps, current: gotToPrevStep }
            })
        }

        let backStepIndex = stepsFollowed.indexOf(currentStep) - 1

        if (backStepIndex >= 0) {
            // log('===> back btn ==>' + stepsFollowed[backStepIndex])
            if (stepsFollowed[backStepIndex] == 'save-your-progress') { // skip if coming back
                let email = this.cookieGet('SaveEmail');
                // log("saved emal ==> " +  email)
                backStepIndex = stepsFollowed.indexOf(currentStep) - 2
            }
            this.setState({
                steps: { ...steps, current: stepsFollowed[backStepIndex] }
            })
        }
    }

    handleCalculatorClick() {
        // log("calculator handler is clicked");
        this.handleClick('property-info-deals');
    }

    handleSidebarClick() {
        // log("sidebar handler called is clicked");
        let gotToPrevStep = (this.state.stepsFollowed.indexOf('property-info-deals') != -1) ? 'property-info-deals' : "property-info-brokers";
        this.handleClick('calculator', '', gotToPrevStep);
    }

    componentDidMount() {
        AddClassInBody();
        let params = {}, queries, temp, i, l, queryString;
        queryString = this.props.location.search;
        if (queryString.indexOf('?') != -1) {
            queryString = decodeURIComponent(queryString.substring(queryString.indexOf('?') + 1));
            queries = queryString.split("&");
            for (i = 0, l = queries.length; i < l; i++) {
                temp = queries[i].split('=');
                if (temp.length > 2) {
                    params[temp[0]] = `${temp[1]}=`;
                } else {
                    params[temp[0]] = temp[1];
                }
            }
        }

        if (Object.keys(params).length != 0) {
            this.cookieSet('referrerId', params.id);
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
                this.cookieSet('utm_campaign', params.utm_campaign);
                this.cookieSet('utm_medium', params.utm_medium);
                this.cookieSet('utm_source', params.utm_source);
                this.cookieSet('utm_term', params.utm_term);

            }
        }

        if (this.cookieGet('utm_campaign')) {
            params['utm_campaign'] = this.cookieGet('utm_campaign');
        }
        if (this.cookieGet('utm_medium')) {
            params['utm_medium'] = this.cookieGet('utm_medium');
        }
        if (this.cookieGet('utm_source')) {
            params['utm_source'] = this.cookieGet('utm_source');
        }
        if (this.cookieGet('utm_term')) {
            params['utm_term'] = this.cookieGet('utm_term');
        }
        if (this.cookieGet('referrerId')) {
            params['referrerId'] = this.cookieGet('referrerId');
        }


        if (this.props.location.href) {
            this.setState({
                pageSource: this.props.location.href
            });
        }
        if (this.cookieGet('postcode_id')) {
            this.setState({
                postcodeId: this.cookieGet('postcode_id')
            });
        }
        if (Object.keys(params).length != 0) {
            if (params.referrerId) {
                this.setState({
                    referrerKey: params.referrerId
                });
            }
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
                this.setState({
                    utmTags: params
                });


            }
        }

    }

    handleSaveProgress(event) {

        if (event.target.id == 'hash-btn-skip-progress') {
            //log('asdf');
            return false;
        }

        if (document.getElementById('progress-email')) {
            let email = document.getElementById('progress-email').value
            // log(validator.isEmail(email));
            if (!(!!email)) {
                if (document.getElementById('progress-email-error')) {
                    document.getElementById('progress-email-error').innerHTML = 'Please enter your email.'
                    document.getElementById('progress-email-error').style.display = 'block';
                }
            } else if (!validator.isEmail(email)) {
                if (document.getElementById('progress-email-error')) {
                    document.getElementById('progress-email-error').innerHTML = 'Please enter valid email.'
                    document.getElementById('progress-email-error').style.display = 'block';
                }
            } else {
                if (document.getElementById('progress-email-error')) {
                    document.getElementById('progress-email-error').style.display = 'none';
                    this.setState({
                        saveProgressEmail: email
                    })
                }
            }
        }
        // log('save progress clicked..');
    }

    validateSteps(step, data = null) {
        // log('validate step called..');
        // log(step)
        // log(data);
        if (step) {
            let state = this.state;
            let currentStep = state.steps.current;
            let funnelData = state.funnelData;

            let flow = this.cookieGet('flow');

            if (flow == 'broker') {
                if (step == 'new-loan' || step == 'refinance') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'brokers'
                    })
                    return 'property-info-brokers';
                }
            }

            if (flow == 'deals') {
                if (step == 'new-loan' || step == 'refinance') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'deals'
                    })
                    return 'property-info-deals';
                }
            }

            if (step == 'new-loan' || step == 'refinance') {
                this.setState({
                    funnelData: { ...funnelData, lead_type: step }
                })
                return step;
            }

            if (step == 'property-info-brokers') {
                if (funnelData.lead_type) { // we must have lead type at this step
                    this.setState({
                        flow: 'brokers'
                    })
                    return step;
                }
            }

            if (step == 'property-info-deals') {
                if (funnelData.lead_type) { // we must have lead type at this step
                    this.setState({
                        flow: 'deals'
                    })
                    return step;
                }
            }

            if (step == 'enter-postcode') {

                if (currentStep == 'deal-info') {

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    if (!!broker) {

                        this.setState({
                            funnelData: { ...funnelData, broker: Number(broker), postcode: Number(postcode) }
                        })

                        return 'book-appointment'; // skip the broker selection process
                    }
                }

                if (!!funnelData.lead_type && !!state.flow) {
                    let aboutProperty = data;
                    if (currentStep == 'property-info-brokers') {
                        this.setState({
                            funnelData: { ...funnelData, aboutProperty }
                        })
                    }

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    let deal = this.cookieGet('deal');

                    if (!!broker) {

                        let items = {};

                        items['broker'] = Number(broker);

                        if (!!deal) {
                            items['deal'] = Number(deal);
                        }

                        items['aboutProperty'] = aboutProperty;

                        this.setState({
                            funnelData: { ...funnelData, ...items }
                        })

                        if (!!deal) {
                            return 'book-appointment';
                        }

                        return 'deal-listing'
                    }

                    return step;
                }
            }

            if (step == 'deal-listing') {

                let aboutProperty = data;

                if (currentStep == 'property-info-deals') {

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    let deal = this.cookieGet('deal');

                    let items = {};

                    if (!!deal) {

                        items['deal'] = Number(deal);

                        if (!!broker) {
                            items['broker'] = Number(broker);
                        }

                        items['aboutProperty'] = aboutProperty;

                        this.setState({
                            funnelData: { ...funnelData, ...items }
                        })

                        if (!!broker) {
                            return 'book-appointment';
                        }

                        return 'enter-postcode'; // skip the broker selection process
                    }
                }

                if (currentStep == 'broker-details') {
                    this.setState({
                        funnelData: { ...funnelData, broker: data }
                    })
                }

                if (!!funnelData.lead_type && !!state.flow) {

                    if (currentStep == 'property-info-deals') {
                        this.setState({
                            funnelData: { ...funnelData, aboutProperty }
                        })
                    }
                    return step;
                }
            }

            if (step == 'deal-info') {
                // log('====> deal info');
                this.setState({
                    prevStep: "property-info-brokers",
                    funnelData: { ...funnelData, deal: data }
                })
                return step;
            }

            if (step == 'broker-finder') {
                // log(data);
                this.setState({
                    funnelData: { ...funnelData, postcode: data[0], suburb: data[1], state: data[2] },
                    postcodeId: data[3]
                })
                // log('====> broker finder step');
                return step;
            }

            if (step == 'broker-details') {
                // log('In broker details')
                this.setState({
                    broker: data
                })
                // log('====> broker details step');
                return step;
            }

            if (step == 'save-your-progress') {
                // log(data);
                this.setState({
                    funnelData: { ...funnelData, broker: data }
                })
                // log('====> save your progress step');
                return step;
            }

            if (step == 'book-appointment') {

                let SavedEmail = this.cookieGet('SaveEmail');
                // log(SavedEmail)
                // log(data);
                if (!!data) {
                    log(data, '=>broker selected');
                    this.setState({
                        funnelData: { ...funnelData, broker: data }
                    })
                }

                // log('====> book-appointment step');
                return step;
            }

            if (step == 'thank-you') {
                let bookAppointment = data;
                this.setState({
                    funnelData: { ...funnelData, bookAppointment }
                })
                // log('====> thank you step');
                return step;
            }

            if (step == 'dashboard') {
                // log('====> dashboard step');
                return step;
            }

            if (step == 'calculator') {
                // log('====> calculator step');
                return step;
            }


        }

        return false;
    }

    handleClick(nextStep, data = null, gotToPrevStep = '') {
        // log('handle click called...')
        // log(nextStep);
        // log(data);
        let nextStepReturned = this.validateSteps(nextStep, data);
        // log('returned route ==> ' + nextStepReturned)
        if (nextStepReturned) {
            let steps = this.state.steps;
            let currentStep = this.state.steps.current;
            let prevSteps = this.state.stepsFollowed

            if (prevSteps.indexOf(currentStep) == -1) {
                if (currentStep == 'refinance' || currentStep == 'new-loan') {
                    prevSteps[1] = currentStep;
                } else if (currentStep == 'property-info-deals' || currentStep == 'property-info-brokers') {
                    prevSteps[2] = currentStep;
                } else {
                    prevSteps.push(currentStep);
                }

            }

            if (prevSteps.indexOf(nextStepReturned) == -1 && nextStepReturned != 'calculator') {
                if (nextStepReturned == 'refinance' || nextStepReturned == 'new-loan') {
                    prevSteps[1] = nextStepReturned;
                } else if (nextStepReturned == 'property-info-deals' || nextStepReturned == 'property-info-brokers') {
                    prevSteps[2] = nextStepReturned;
                } else {
                    prevSteps.push(nextStepReturned);
                }
            }

            this.setState({
                steps: { ...steps, current: nextStepReturned },
                prevStep: currentStep,
                stepsFollowed: prevSteps,
                gotToPrevStep: gotToPrevStep
            })
        }

        log('change step is clicked....');
    }

    handleBrokerContactMe(broker) {

        log(broker);
        let deal = this.cookieGet('deal');
        let email = this.cookieGet('SaveEmail');
        let flow = this.state.flow;

        log(flow, "==> contact me flow => ")
        log(email, "==> contact me email")

        if (flow == "brokers") {

            if (deal != undefined) {
                this.handleClick('book-appointment', broker);
                return true;
            }

            if (email != undefined) {
                this.handleClick('deal-listing', broker);
            } else {
                this.handleClick('save-your-progress', broker);
            }
        }

        if (flow == 'deals') {
            this.handleClick('book-appointment', broker);
        }

    }


    handleBrokerClick(broker) {
        this.handleClick('broker-details', broker.broker_id);
    }

    handleBookAppointmentData(data) {
        log('appointment handle is called..');
    }

    dealClickHandler(deal = null, from = '') {

        log("deal click handler called...")

        if (!!from && from == 'deal-info') {

            let state = this.state;

            if (state.flow == 'brokers') {
                this.handleClick('book-appointment');
            }

            if (state.flow == 'deals') {
                let email = this.cookieGet('SaveEmail')

                let broker = this.cookieGet('broker');

                if (broker != undefined) {
                    this.handleClick('enter-postcode');
                    return true;
                }

                if (email != undefined) {
                    this.handleClick('enter-postcode');
                } else {
                    this.handleClick('save-your-progress');
                }
            }

            return true;
        }
        log(deal);
        this.handleClick('deal-info', deal);
        log('deal handler called');
    }

    skipBtnHandler(step) {

        log('skip this step called...');
        log(step);
        let state = this.state;
        let cookieFlow = this.cookieGet('flow');
        let broker = this.cookieGet('broker');
        let deal = this.cookieGet('deal');
        log(' flow ==>' + state.flow)

        if (state.flow == 'brokers' && step == 'enter-postcode') {
            this.skipToStep('deal-listing');
        }

        if (state.flow == 'deals' && step == 'enter-postcode') {
            this.skipToStep('book-appointment');
        }

        if (state.flow == 'brokers' && step == 'save-your-progress') {
            this.skipToStep('deal-listing');
        }

        if (state.flow == 'deals' && step == 'save-your-progress') {
            this.skipToStep('enter-postcode');
        }

        if (state.flow == 'brokers' && (step == 'deal-listing' || step == 'deal-details')) {
            this.skipToStep('book-appointment');
        }



        if (state.flow == 'deals' && (step == 'deal-listing' || step == 'deal-details')) {
            if (broker != undefined) {
                this.skipToStep('book-appointment');
                return false;
            }
            this.skipToStep('enter-postcode');
        }

        if (state.flow == 'brokers' && (step == 'broker-details' || step == 'broker-finder')) {
            log(deal == 'undefined')
            if (deal == undefined) {
                this.skipToStep('deal-listing');
            } else {
                this.skipToStep('book-appointment');
            }
        }

        if (state.flow == 'deals' && (step == 'broker-details' || step == 'broker-finder')) {
            this.skipToStep('book-appointment');
        }
    }

    skipToStep(step) {
        this.handleClick(step);
    }

    renderStep() {

        let AllProps = {
            handleChangeStep: this.handleClick,
            currentState: this.state,
            backBtnHandler: this.backBtnHandler,
            funnelData: this.state.funnelData,
            skipBtnHandler: this.skipBtnHandler,
            getSteps: this.getSteps,
            handleBookAppointmentData: this.handleBookAppointmentData,
            handleBrokerContactMe: this.handleBrokerContactMe,
            handleCalculatorClick: this.handleCalculatorClick,
            handleSidebarClick: this.handleSidebarClick,
            handleSaveYourProgress: this.handleSaveYourProgress,
        };
        let currentState = this.state.steps.current;

        if (currentState == 'get-started') {
            return <LoanType {...AllProps} />
            // return <FunnelBrokerDetails {...AllProps} />

        }

        if (currentState == 'new-loan' || currentState == 'refinance') {
            return <NewLoan {...AllProps} />
        }

        if (currentState == 'calculator') {
            return <Calculator {...AllProps} />
        }

        if (currentState == 'property-info-brokers') {
            return <PropertyInfo {...AllProps} Minimumloanamount={this.loanAmount} />
        }

        if (currentState == 'property-info-deals') {
            return <PropertyInfo {...AllProps} Minimumloanamount={this.loanAmount} />
        }

        if (currentState == 'enter-postcode') {
            return <FunnelChooseBroker {...AllProps} />
        }

        if (currentState == 'deal-listing') {
            return <FunnelDeals  {...AllProps} dealClickHandler={this.dealClickHandler} />
        }

        if (currentState == 'deal-info') {
            return <FunnelDealDetails  {...AllProps} dealID={this.state.funnelData.deal} dealClickHandler={this.dealClickHandler} />
        }

        if (currentState == 'broker-finder') {
            return <FunnelBrokers  {...AllProps} handleBrokerClick={this.handleBrokerClick} />
        }

        if (currentState == 'broker-details') {
            let brokerId = this.state.broker;
            return <FunnelBrokerDetails brokerId={brokerId} {...AllProps} />
        }

        if (currentState == 'save-your-progress') {
            return <SaveYourProgress {...AllProps} />
        }

        if (currentState == 'book-appointment') {
            return <BookAppointment {...AllProps} />
        }

        if (currentState == 'thank-you') {
            return <ThankYou {...AllProps} />
        }

        if (currentState == 'dashboard') {
            return <WelComeToDashboard {...AllProps} />
        }

        return <h1>Some Error Occurred</h1>
    }

    render() {
        return (
            <Layout>
                {this.renderStep()}
            </Layout>
        )
    }
}

const StickyBottomBar = (props) => {
    let StickStyle = {
        'display': (props.hideSkip) ? 'none' : 'block'
    }
    return (
        <Fragment>
            <section className={cx('bottom-sticky-buttons-section funnelBottomStickyButtonsSection', Styles.funnelBottomStickyButtonsSection)}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={cx("col-xl-12 col-lg-12 mr-auto ml-auto", "bottom-sticky-buttons")}>
                            <div className={"sticky-getstartd"} style={{ 'display': 'none' }}>
                                <div className={cx("d-flex", "justify-content-center")}>
                                    <p className={"descrption"}>Fill our 2 min. questionaire so we can help you in your home loan project</p>
                                    <div className={cx("sticky-buttons", "btn-right")}>
                                        <a className={cx("btn", "btn-right-link", "active")} href="javascript://">
                                            <span className={"btn-txt"}>Get started</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"sticky-buttons-main"}>
                                <div className={cx("row")}>
                                    <div className={cx("col-md-2 col-lg-4", "sticky-buttons", "btn-left")}>
                                        <a className={cx("btn", "btn-left-link")} href="javascript://" onClick={props.backBtnHandler}>
                                            {/* <i className={cx("fa", "fa-chevron-left")} aria-hidden="true"></i> */}
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-40.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M17.7235786,23.7204755 L18.444132,22.9984485 C18.8404319,22.6013317 19.1719795,22.2764214 19.1809189,22.2764214 C19.1898453,22.2764214 20.2156076,23.2967371 21.4603719,24.5438098 L23.7235786,26.8111982 L25.9867852,24.5438098 C27.2315495,23.2967371 28.2572988,22.2764214 28.2662253,22.2764214 C28.2751647,22.2764214 28.6067252,22.6013317 29.0030251,22.9984485 L29.7235786,23.7204755 L26.7276402,26.722027 C25.0798779,28.3728882 23.7280483,29.7235786 23.7235786,29.7235786 C23.7191089,29.7235786 22.3672792,28.3728882 20.719517,26.722027 L17.7235786,23.7204755 Z"
                                                                id="Fill-1-Copy-4" transform="translate(23.723579, 26.000000) rotate(-270.000000) translate(-23.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className={"btn-txt"}>Back</span>
                                        </a>
                                    </div>
                                    <div className={cx("col-md-10 col-lg-8", "sticky-buttons", "btn-right", Styles.skipbtnmain)} style={StickStyle}>
                                        <span className={Styles.btnlabel}>Cant find the deal you want?</span>
                                        <a className={cx("btn", "btn-right-link", "active", Styles.skipbtn, Styles.active)} href="javascript://" onClick={() => {
                                            props.skipBtnHandler(props.skipFrom)
                                        }}>
                                            <span className={"btn-txt"}>{props.skipBtnText}</span>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}


class FunnerProgressHeader extends React.Component {
    constructor(props) {
        super(props);

        this.myRefProgressHeader = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0, this.myRefProgressHeader.current.offsetTop)
    }
    render() {
        let completeClass = Styles.complete;
        let steps = this.props.getSteps() || [];
        let currentlyActive = this.props.active || ''

        var length = steps.length;
        for (var i = 0; i < length; i++) {
            if (steps[i].labelText == currentlyActive) {
                steps[i].className = Styles.active
                completeClass = ''
            } else {
                steps[i].className = completeClass
            }
        }

        return (
            <section className={cx(Styles.loandealProcessSection)} ref={this.myRefProgressHeader}>
                <div className={cx('container')}>
                    <div className={cx('row')}>
                        <div className={cx('col-12', Styles.loandealProcessInnr)}>
                            <div className={cx(Styles.loandealProcess)}>
                                <div className={cx('d-flex', 'justify-content-center', Styles.bsWizard)}>
                                    {steps.map((step, index) => (
                                        <div key={index} className={cx(Styles.bsWizardStep, step.className)}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-12', Styles.stepCount)}>
                                                    <div className={cx(Styles.progress)}>
                                                    </div>
                                                    <span className={cx(Styles.progressJoint)}></span>
                                                    <div className={cx(Styles.bsWizardDot)}>
                                                        <span className={cx(Styles.number)}>
                                                            <small className={cx(Styles.count)}>{index + 1}</small>
                                                        </span>
                                                        <span className={cx(Styles.tick)}>
                                                            <small className={cx(Styles.chkicon)}>
                                                                <i className={cx(Styles.fa, Styles.faCheck)}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </i>
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                                    <div className={cx(Styles.bsWizardStepnum)}><span dangerouslySetInnerHTML={{ __html: step.labelText }}></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className={cx(Styles.bsWizardStep, Styles.complete)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    2
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>The property</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep, Styles.active)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    3
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Your rates</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    4
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Your broker</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep)}> */}
                                    {/* <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                           <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}><small className={cx(Styles.count)}>
                                                5
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Appointment</span></div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}



const FunnelRightSection = (props) => {

    let Element;

    if (props.section == '1') {
        Element = () => (
            <div className={Styles.loandealStepRightcontnt}>
                <div className={Styles.loantypContnt}>
                    <div className={cx('card', Styles.card)}>
                        <div className={cx('card-body', Styles.cardBody)}>
                            <h3 className={cx(Styles.sectionTitle, Styles.titleWithImg)}>

                                <img className={Styles.titleimg} src={flagIcon} alt="flagIcon" />
                                <span className={Styles.titletxt}> We are a Sydney born company</span>


                            </h3>
                            <p className={Styles.sectionSummry}>Completely <strong>free</strong> to consumers with no locked in contracts, HashChing connects you directly to verified mortgage brokers who can further negotiate a better rate from the lenders and save you time, hassle and money.</p>

                            <div className={Styles.dealcountStatus}>
                                <div className={Styles.countsDetailMain}>
                                    <span className={Styles.countsDetailIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="45" viewBox="0 0 35 45">
                                            <g fill="none" fillRule="evenodd">
                                                <g fill="#3C648A">
                                                    <path d="M4.887 9.688c-.004-.054.822-.527 1.57-1.095l-.37.281A12.383 12.383 0 0 1 8.86 7.31l-.45.186c1.096-.446 2.239-.789 3.412-.946l-.482.063c2.142-.278 4.3-.036 6.44.095.999.06 2.005.105 3.006.033.61-.046 1.224-.12 1.823-.251.578-.126 1.118-.336 1.659-.572.587-.256 1.106-.629 1.598-1.034-.469.39-.179 2.007.132 3.167l1.137 4.241.978 3.652c.158.59.299 1.11.358 1.308-.065.122-.231.34-.136.22-.278.348-.59.665-.949.94l.371-.282c-.37.282-.774.51-1.203.687l.449-.186a7.399 7.399 0 0 1-1.794.46c.16-.022.322-.044.482-.064-1.258.165-2.536.099-3.793.013-1.345-.092-2.684-.219-4.034-.23-1.521-.007-3.062.15-4.529.583-1.47.435-3.004.964-4.204 1.893-.52.402-1.787 1.322-1.758 2.047.034.883.632 1.714 1.794 2.495.501-.472.851-.795 1.052-.97.174-.15.349-.295.534-.432l-.371.282a12.252 12.252 0 0 1 2.702-1.534l-.449.186c1.088-.448 2.23-.79 3.395-.95l-.482.064c1.747-.23 3.506-.104 5.25.023.85.064 1.697.13 2.547.153.953.026 1.886-.031 2.83-.167 1.737-.247 3.462-1.063 4.592-2.416.36-.432.695-.88.965-1.368.12-.214.225-.436.31-.663.135-.379.121-.795.044-1.18-.006-.023-.009-.048-.015-.071-.029-.12-.065-.242-.097-.361l-.514-1.92-.801-2.988-.903-3.37-.833-3.107-.588-2.195-.145-.542c-.245-.915-.948-1.592-1.923-1.717a2.224 2.224 0 0 0-1.677.46 2.85 2.85 0 0 0-.6.66 7.05 7.05 0 0 1-.192.264c.096-.12.187-.24.283-.36-.28.356-.596.674-.958.95l.371-.282c-.352.265-.74.483-1.148.654l.449-.186a7.417 7.417 0 0 1-1.836.47c.16-.02.322-.043.482-.064-1.404.18-2.828.087-4.23-.008-1.51-.106-3.026-.233-4.546-.165-3.318.145-6.613 1.331-9.163 3.462-.38.318-.734.66-1.064 1.026-.13.141-.257.288-.382.435-.149.174-.297.349-.434.535-.375.52 3.245 1.097 3.265 1.252z" />
                                                    <path d="M.654 7.652l.211.79.575 2.144.853 3.186 1.038 3.873L4.47 21.89l1.147 4.281 1.074 4.01.904 3.373.65 2.426c.103.385.202.767.308 1.15l.014.052c.117.435.436.842.834 1.068.384.214.94.302 1.372.165.446-.142.877-.41 1.106-.83.225-.417.312-.878.188-1.341l-.212-.79-.574-2.144-.854-3.186-1.038-3.873-1.137-4.244-1.147-4.282-1.074-4.01-.904-3.372-.65-2.427c-.104-.384-.202-.767-.309-1.15l-.013-.051a1.809 1.809 0 0 0-.834-1.068c-.384-.215-.94-.303-1.372-.165-.446.141-.877.41-1.106.829-.225.421-.311.882-.188 1.341z" />
                                                </g>
                                                <path stroke="#3C648A" strokeLinecap="round" strokeWidth="4" d="M5.415 42.976h27.574" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={Styles.countsDetail}>
                                        <span className={cx(Styles.digit)}>2015</span>
                                        <span className={cx(Styles.digitInfo)}>The year founded  </span>
                                    </span>
                                </div>
                                <div className={cx(Styles.countsDetailMain)}>
                                    <span className={cx(Styles.countsDetailIcon)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="42" viewBox="0 0 52 42">
                                            <g fill="#3C648A" fillRule="nonzero">
                                                <path d="M16.774 17.989v-2.14c3.174 0 5.785-2.61 5.785-5.784S19.948 4.28 16.774 4.28c-3.173 0-5.784 2.61-5.784 5.759.039 3.23 2.594 5.81 5.784 5.81v2.14zm0 0v2.14c-5.554 0-9.997-4.484-10.064-10.064C6.71 4.527 11.237 0 16.774 0 22.312 0 26.84 4.527 26.84 10.065c0 5.537-4.527 10.064-10.065 10.064v-2.14zM36.903 20.425v-2.22a3.957 3.957 0 0 0 3.946-3.947c0-2.118-1.827-3.946-3.946-3.946a3.957 3.957 0 0 0-3.946 3.946c0 2.14 1.796 3.946 3.946 3.946v2.22zm0 0v2.22c-4.608 0-8.387-3.8-8.387-8.387 0-4.601 3.742-8.387 8.387-8.387 4.571 0 8.387 3.816 8.387 8.387 0 4.602-3.741 8.387-8.387 8.387v-2.22zM51.303 39.695c-.052 1.225-1.074 2.175-2.284 2.122-1.209-.052-2.147-1.088-2.095-2.312.297-7.02-3.294-9.403-10.633-9.403-1.21 0-2.192-.994-2.192-2.219 0-1.225.981-2.219 2.192-2.219 9.516 0 15.44 3.931 15.012 14.031zM34.32 39.406c.007 1.226-.968 2.225-2.178 2.233-1.21.008-2.198-.98-2.205-2.205-.045-7.184-3.623-10.872-12.709-10.872-7.595 0-12.752 3.987-12.708 10.844.008 1.225-.967 2.225-2.177 2.233-1.21.008-2.198-.979-2.205-2.204-.062-9.629 7.287-15.31 17.09-15.31 11.52 0 17.031 5.682 17.091 15.281z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={cx(Styles.countsDetail)}>

                                        <span className={cx(Styles.digit)}>60,000</span>
                                        <span className={cx(Styles.digitInfo)}>Borrowers helped  </span>
                                    </span>
                                </div>
                                <div className={cx(Styles.countsDetailMain)}>
                                    <span className={cx(Styles.countsDetailIcon)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="46" viewBox="0 0 41 46">
                                            <g fill="#3C648A" fillRule="evenodd">
                                                <path d="M22.353 41.176l-2.086.853-1.635-.74C9.89 37.426 4.194 29.937 4.194 20.332v-7.692l16.242-9.037L37.13 12.64v7.464c-.056 9.719-5.809 17.265-14.777 21.073zm17.848-29.349c0-.76-.431-1.468-1.13-1.794L21.045.245c-.645-.327-1.399-.327-1.99 0L1.512 10.033c-.7.326-1.076 1.033-1.076 1.794v8.693c0 10.876 6.403 19.564 16.304 23.915l2.421 1.033c.269.163.538.163.807.163.27 0 .538-.054.807-.163l2.744-1.196C33.636 40.03 40.2 31.342 40.2 20.248v-8.42z" />
                                                <path d="M29.725 17.751L19.147 29.725l-.002-.002v.002l-6.611-7.484 2.644-2.993 3.967 4.49 7.936-8.98z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={cx(Styles.countsDetail)}>
                                        <span className={cx(Styles.digit)}>2000+</span>
                                        <span className={cx(Styles.digitInfo)}>Certified Brokers  </span>
                                    </span>
                                </div>
                            </div>
                            <div className={cx(Styles.featurdlogoSection)}>
                                <h3 className={cx(Styles.sectionTitle)}>We are featured in</h3>
                                <ul className={cx(Styles.feturedLogo)}>
                                    <li><div className={cx(Styles.featuredLogofinancial, Styles.feturedLogoImgs)}></div></li>
                                    <li><div className={cx(Styles.featuredLogoadviser, Styles.feturedLogoImgs)}></div></li>
                                    <li> <div className={cx(Styles.featuredLogosmh, Styles.feturedLogoImgs)}></div></li>
                                    <li><div className={cx(Styles.featuredLogonews, Styles.feturedLogoImgs)}></div></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        Element = () => (
            <div className={cx(Styles.aboutpropContnt)}>
                <div className={cx('card', Styles.card)}>
                    <div className={cx('card-body', Styles.cardBody)}>
                        <h3 className={cx(Styles.sectionTitle)}>See how much you <br />could borrow </h3>
                        <p className={cx(Styles.sectionSummry)}>Our borrowing calculator estimate how much you could borrow and what property price range you could afford.</p>
                        <p className={cx(Styles.clickmoreCalc)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                <defs>
                                    <filter id="a" width="166.7%" height="166.7%" x="-33.3%" y="-33.3%" filterUnits="objectBoundingBox">
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                                        <feMerge>
                                            <feMergeNode in="shadowMatrixOuter1" />
                                            <feMergeNode in="SourceGraphic" />
                                        </feMerge>
                                    </filter>
                                    <linearGradient id="b" x1="84.177%" x2="16.321%" y1="8.32%" y2="102.994%">
                                        <stop offset="0%" stopColor="#29D0F7" />
                                        <stop offset="100%" stopColor="#1984C9" />
                                    </linearGradient>
                                </defs>
                                <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(4 2)">
                                    <circle cx="18" cy="18" r="18" fill="url(#b)" fillRule="nonzero" />
                                    <path fill="#FFF" d="M12.133 13.188c0-.294.24-.532.534-.532h10.666c.295 0 .534.238.534.531v2.657a.532.532 0 0 1-.534.531H12.667a.532.532 0 0 1-.534-.531v-2.656zm10.134 7.437a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.063c0 .586-.477 1.062-1.066 1.062zm0 3.719A1.065 1.065 0 0 1 21.2 23.28c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.062c0 .587-.477 1.063-1.066 1.063zM18 20.625a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.063c0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.062c0 .587-.478 1.063-1.067 1.063zm-4.267-3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.063 0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.062 0 .587-.478 1.063-1.067 1.063zM12.133 27h11.734A2.13 2.13 0 0 0 26 24.875v-12.75A2.13 2.13 0 0 0 23.867 10H12.133A2.13 2.13 0 0 0 10 12.125v12.75A2.13 2.13 0 0 0 12.133 27z" />
                                    <path fill="#FFF" d="M12.133 13.188c0-.294.24-.532.534-.532h10.666c.295 0 .534.238.534.531v2.657a.532.532 0 0 1-.534.531H12.667a.532.532 0 0 1-.534-.531v-2.656zm10.134 7.437a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.063c0 .586-.477 1.062-1.066 1.062zm0 3.719A1.065 1.065 0 0 1 21.2 23.28c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.062c0 .587-.477 1.063-1.066 1.063zM18 20.625a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.063c0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.062c0 .587-.478 1.063-1.067 1.063zm-4.267-3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.063 0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.062 0 .587-.478 1.063-1.067 1.063zM12.133 27h11.734A2.13 2.13 0 0 0 26 24.875v-12.75A2.13 2.13 0 0 0 23.867 10H12.133A2.13 2.13 0 0 0 10 12.125v12.75A2.13 2.13 0 0 0 12.133 27z" />
                                </g>
                            </svg>

                            <span>Try our <a href="javascript:void(0);" onClick={(event) => {
                                if (!!props.handleSidebarClick) {
                                    props.handleSidebarClick();
                                }
                            }}>Borrowing calculator</a></span>
                        </p>
                    </div>
                </div>
                <div className={cx('card', Styles.card)}>
                    <div className={cx('card-body', Styles.cardBody)}>
                        <h3 className={cx(Styles.sectionTitle)}>Why do we have better <br /> rates?</h3>
                        <p className={cx(Styles.sectionSummry)}>We have 2000+ brokers in our network. They know the ins and out of the market and can get the best deals out-<br />there. <strong>They negotiate for you and will get you <br /> unbeatable rates.</strong></p>
                        <div className={cx(Styles.paneloflenders)}>
                            <h3 className={cx(Styles.sectionTitle)}>Panel of Lenders</h3>

                            <div className={Styles.lendrsImgs}>
                                <div className={cx(Styles.lendrimg, Styles.lendrscommbank)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsing)}> </div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsnab)}></div>
                                {/*  <div className={cx(Styles.lendrimg, Styles.lendrsmacquarie)}> </div> */}
                                <div className={cx(Styles.lendrimg, Styles.lendrsnewcastle)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrscitibank)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsanz)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsvirgin)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsauswide)}></div>
                                {/* <div className={cx(Styles.lendrimg, Styles.lendrswestpac)}> </div> */}
                            </div>
                            <p className={Styles.plusmoreText}>And 100+ more…</p>
                        </div>
                        <h3 className={cx(Styles.sectionTitle)}>Why we don’t show the lender</h3>
                        <p className={cx(Styles.sectionSummry)}>Our highly experienced partner mortgage brokers negotiate a better deal from lenders directly and lenders don&apos;t want their deals to be advertised openly. Speak to our broker and they will disclose the lender to you. Don&apos;t worry, our partner brokers have plenty more deals from other lenders and they will source a better deal most suited to your needs at no cost to you.</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={cx('col-lg-6', Styles.loandealStepRight)}>
            <Element />
        </div>
    )
}

const LoanType = (props) => {
    return (
        <Fragment>
            <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor)}>
                <FunnerProgressHeader {...props} active={'Your enquiry'} />
                <section className={Styles.loandealStepSection}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                <div className={'row'}>
                                    <div className={cx('col-lg-6', Styles.loandealStepLeft)}>
                                        <div className={Styles.loandealStepLeftcontnt}>
                                            <div className={cx('card', Styles.card)}>
                                                <div className={cx('card-body', Styles.cardBody)}>
                                                    <h2 className={cx(Styles.sectionTitle)}>What type of home loan <br />are you looking for?</h2>
                                                    <div className={cx('text-center', Styles.sectionButton)}>
                                                        <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('new-loan');
                                                        }} >New Home Loan</a>
                                                        <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('refinance');
                                                        }} >Refinance a Home Loan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FunnelRightSection section="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            {/* <StickyBottomBar {...props} hideSkip={true}> </StickyBottomBar> */}
        </Fragment>
    )
}

const NewLoan = (props) => {

    let removeBrokersButton = false, removeDealsButton = false;
    log(props.currentState.stepsFollowed.indexOf('property-info-deals') != -1 || props.currentState.stepsFollowed.indexOf('property-info-brokers') != -1);
    if (props.currentState.stepsFollowed.indexOf('property-info-deals') != -1 || props.currentState.stepsFollowed.indexOf('property-info-brokers') != -1) {
        if (props.currentState.stepsFollowed.indexOf('property-info-deals') != -1) {
            removeBrokersButton = true;
        } else {
            removeDealsButton = true;
        }
    }

    log(removeBrokersButton);
    log(removeDealsButton);


    return (
        <Fragment>
            <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor)}>
                <FunnerProgressHeader {...props} active={'Your enquiry'} />
                <section className={cx(Styles.loandealStepSection)}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                <div className={"row"}>
                                    <div className={cx('col-lg-6', Styles.loandealStepLeft)}>
                                        <div className={cx(Styles.loandealStepLeftcontnt)}>
                                            <div className={cx('card', Styles.card)}>
                                                <div className={cx('card-body', Styles.cardBody)}>
                                                    <h2 className={cx(Styles.sectionTitle)}>I would like to find</h2>
                                                    <div className={cx('text-center', Styles.sectionButton)}>
                                                        <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('calculator', '', 'new-loan');
                                                        }} >How much can I borrow</a>
                                                        {!removeBrokersButton && <a className={cx('btn', Styles.blueBtn, Styles.btn, Styles.btnsize)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('property-info-brokers');
                                                        }} >Right broker in my area</a>}
                                                        {!removeDealsButton && <a className={cx('btn', Styles.blueBtn, Styles.btn, Styles.btnsize)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('property-info-deals');
                                                        }}>Home loan deals available to me</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FunnelRightSection section="1" {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <StickyBottomBar {...props} hideSkip={true} />
        </Fragment>
    )
}

class PropertyInfo extends React.Component {

    constructor(props) {
        super(props);
        this.validateForm = this.validateForm.bind(this);
        log("===> Funnel Data");
        log(this.props.funnelData.aboutProperty)
        this.state = {
            formData: {
                investmentType: this.getDefaultValue('investmentType'),
                amount: this.getDefaultValue('amount'),
                estimated: this.getDefaultValue('estimated'),
                rate: this.getDefaultValue('rate'),
                currentLender: this.getDefaultValue('currentLender'),
                loan_type: props.funnelData.lead_type || ''
            },
            errors: {}
        }
        log(props);
    }

    getDefaultValue(value) {
        if (!!this.props.funnelData && !!this.props.funnelData.aboutProperty) {
            return this.props.funnelData.aboutProperty[value];
        }
        return ''
    }

    validNumber(value) {
        var regex = /^[0-9,]+$/;
        return (!regex.test(value)) ? false : true;
    }

    validateForm(event, validation = 'all', value = '') {

        log(validation)
        log(value);

        let formData = this.state.formData;

        let LoanType = formData.loan_type

        let investmentType = formData.investmentType;

        let estimated = formData.estimated;

        let amount = formData.amount;

        let rate = formData.rate;

        let currentLender = formData.currentLender;


        if (validation == 'all' || validation == 'investmentType') {

            if (validation != 'all') { // to trigger single validation on the input
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'investmentType': value }
                    }
                })
            } else { // to trigger when next button is clicked
                value = formData.investmentType;
            }

            if (!(!!value)) {
                let errors = this.state.errors;
                errors.investmentType = "Investment type required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.investmentType;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'amount') {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'amount': value }
                    }
                })
            } else {
                value = formData.amount;
            }

            let errors = this.state.errors;
            // Minimum loan amount allowed is 70, 000
            const minLoan = (this.props.Minimumloanamount.elements.label_minimumloanamount.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            if (!(!!value)) {
                errors.amount = "Please enter amount.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0) {
                errors.amount = "Please enter a valid amount";
            } else if (value < minLoan) {
                errors.amount = 'Minimum loan amount allowed is $' + minLoan;
            } else {
                let errors = this.state.errors;
                delete errors.amount;
                this.setState({
                    errors: errors
                })
            }

        }

        if (validation == 'all' || validation == 'amount') {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'amount': value }
                    }
                })
            } else {
                value = formData.amount;
            }

            let errors = this.state.errors;
            // Minimum loan amount allowed is 70, 000
            const minLoan = this.props.Minimumloanamount.elements.label_minimumloanamount.value;

            if (!(!!value)) {
                errors.amount = "Please enter loan amount.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0) {
                errors.amount = "Please enter a valid amount";
            } else if (value < minLoan) {
                errors.amount = `Minimum loan amount allowed is $${(minLoan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            } else {
                let errors = this.state.errors;
                delete errors.amount;
                this.setState({
                    errors: errors
                })
            }
        }


        if (validation == 'all' || validation == 'estimated') {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'estimated': value }
                    }
                })
            } else {
                value = formData.estimated;
            }
            console.log("value" + value)
            console.log('amount' + amount)

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.estimated = "Please enter property value.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0) {
                errors.estimated = "Please enter a valid amount";
            } else if (parseInt(value) > 0 && parseInt(value) <= parseInt(amount)) {
                errors.estimated = "Your property value should be greater than mortgage loan amount.";
            } else {
                let errors = this.state.errors;
                delete errors.estimated;
                this.setState({
                    errors: errors
                })
            }
        }

        if (LoanType == 'refinance' && (validation == 'all' || validation == 'rate')) {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'rate': value }
                    }
                })

            } else {
                value = formData.rate;
            }

            let errors = this.state.errors;

            log(validator.isInt(value));

            log('converted ' + Number(value))

            if (!(!!value)) {
                errors.rate = "Please enter rate.";
                this.setState({
                    errors: errors
                })
            } else if (value <= 0 || value >= 100) {
                errors.rate = "Please enter a valid rate";
            } else if (isNaN(Number(value))) {
                errors.rate = "Please enter a valid rate";
            } else if (validator.isInt(value) && !validator.isInt(value, { allow_leading_zeroes: false })) {
                errors.rate = "Please enter a valid rate";
            } else {
                let errors = this.state.errors;
                delete errors.rate;
                this.setState({
                    errors: errors
                })
            }
        }

        if (LoanType == 'refinance' && (validation == 'all' || validation == 'currentLender')) {

            if (validation != 'all') {

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'currentLender': value }
                    }
                })

            } else {
                value = formData.currentLender;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.currentLender = "Please enter current Lender.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.currentLender;
                this.setState({
                    errors: errors
                })
            }
        }

        let allErrors = this.state.errors;

        if (Object.keys(allErrors).length === 0
            && allErrors.constructor === Object && !validator.isEmpty(investmentType)
            && !validator.isEmpty(amount) && !validator.isEmpty(estimated) && validation == 'all') {
            log('form valid');
            var currentStep = this.props.currentState.steps;
            log("====>" + currentStep);

            if (currentStep.current == 'property-info-brokers') {
                log('===> you are now here.');
                this.props.handleChangeStep('enter-postcode', this.state.formData);
            }

            if (currentStep.current == 'property-info-deals') {
                this.props.handleChangeStep('deal-listing', this.state.formData);
            }
        }

    }

    render() {

        let activeClass, toLive = '', toInvest = '', investmentType = '';
        investmentType = this.state.formData.investmentType;
        if (investmentType == 'to-live') {
            toLive = Styles.active;
            toInvest = ''
        }

        if (investmentType == 'to-invest') {
            toInvest = Styles.active;
            toLive = ''
        }

        return (
            <Fragment>
                <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor)}>
                    <FunnerProgressHeader {...this.props} active={'The property'} />
                    <section className={cx(Styles.loandealStepSection)}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                    <div className={'row'}>
                                        <div className={cx('col-lg-6', Styles.loandealStepLeft)}>
                                            <div className={cx(Styles.loandealStepLeftcontnt, Styles.aboutStepContnt)}>
                                                <div className={cx('card', Styles.card)}>
                                                    <div className={cx('card-body', Styles.cardBody)}>
                                                        <h2 className={cx(Styles.sectionTitle)}>About the property</h2>
                                                        <div className={Styles.formFields}>
                                                            <form onSubmit={(event) => {
                                                                log(event);
                                                            }}>
                                                                <div className={Styles.proprtyFields}>
                                                                    <div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>Purpose of the loan</label>
                                                                        <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                                            <div className={cx('btn-group', Styles.btnGroup)} role="group">

                                                                                <label className={cx('btn', Styles.btn, toLive)}>To Live In
                                                                                    <input type="radio" name="investment" value="to-live" onChange={((event) => {
                                                                                        this.validateForm(event, 'investmentType', event.target.value);
                                                                                    })} />
                                                                                </label>
                                                                                <label className={cx('btn', Styles.btn, toInvest)}>To Invest
                                                                                    <input type="radio" name="investment" value="to-invest" onChange={((event) => {
                                                                                        this.validateForm(event, 'investmentType', event.target.value);
                                                                                    })} />
                                                                                </label>
                                                                            </div>
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.investmentType) ? 'block' : 'none' }}>
                                                                                You must agree before submitting.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>Mortgage Loan Amount</label>
                                                                        <div className={cx('input-group', Styles.inputGroup)}>
                                                                            <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                            </div>
                                                                            <input type="text" className={cx('form-control', Styles.formControl)} value={this.state.formData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} placeholder="How much do you want to borrow" onChange={((event) => {
                                                                                this.validateForm(event, 'amount', event.target.value);
                                                                            })} />
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.amount) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.amount}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>Estimated Property Value</label>
                                                                        <div className={cx('input-group', Styles.inputGroup)}>
                                                                            <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                            </div>
                                                                            <input type="text" className={cx('form-control', Styles.formControl)} value={this.state.formData.estimated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} placeholder="Enter an average" onChange={((event) => {
                                                                                this.validateForm(event, 'estimated', event.target.value);
                                                                            })} />
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.estimated) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.estimated}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type == 'refinance') ? 'block' : 'none' }}>
                                                                        <label>Current rate</label>
                                                                        <div className={cx('input-group', Styles.inputGroup, Styles.rightinputGroup)}>

                                                                            <input type="text" className={cx('form-control', Styles.formControl)} placeholder="" value={this.state.formData.rate} onChange={((event) => {
                                                                                this.validateForm(event, 'rate', event.target.value);
                                                                            })} />
                                                                            <div className={cx('input-group-append', Styles.inputGroupAppend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>%</div>
                                                                            </div>
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.rate) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.rate}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type == 'refinance') ? 'block' : 'none' }}>
                                                                        <label>Current Lender</label>
                                                                        <select name="" value={this.state.formData.currentLender} className={cx('form-control', Styles.formControl)} onChange={((event) => {
                                                                            this.validateForm(event, 'currentLender', event.target.value);
                                                                        })}>
                                                                            <option value="">Select</option>
                                                                            <option>CBA</option>
                                                                            <option>NAB</option>
                                                                            <option>ANZ</option>
                                                                            <option>Westpac</option>
                                                                            <option>Other</option>
                                                                        </select>
                                                                        <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.currentLender) ? 'block' : 'none', 'width': '100%' }}>
                                                                            {this.state.errors.currentLender}
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                                        <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={this.validateForm}>Next</a>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <FunnelRightSection {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <StickyBottomBar {...this.props} hideSkip={true}></StickyBottomBar>
            </Fragment>
        )
    }
}

class BookAppointment extends React.Component {

    constructor(props) {

        super(props);

        this.validateForm = this.validateForm.bind(this);
        this.timeSelectionDropdown = this.timeSelectionDropdown.bind(this);
        this.submitLead = this.submitLead.bind(this);

        this.state = {
            formData: {
                best_day: null,
                best_time: '',
                first_name: '',
                email: '',
                mobile: '',
                agree: false
            },
            ar_best_time: [],
            errors: {},
            verifiedNumber: '',
            codeVerified: false,
            showCodeVerification: false,
            uuid: '',
            mobileCode: '',
            submissionError: false,
            submissionErrorMessage: '',
            ajaxCallStatus: false
        }
    }

    convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return hours + ':' + minutes;
    }

    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

    submitLead() {

        let pCode = this.cookieGet('pCode');

        let postcode = this.cookieGet('postcode');

        let sCode = this.cookieGet('stateCode');

        let suburbcode = this.cookieGet('suburb');



        this.setState({
            submissionError: false,
            submissionErrorMessage: ''
        })



        let formData = this.state.formData

        let leadInfo = {}
        let datanew = {}
        datanew.code = this.state.mobileCode;
        datanew.codeKey = this.state.uuid;

        let timeParts = formData.best_time.split('-')[0];

        let contactTime = this.convertTime12to24(timeParts);

        log(this.props.funnelData);

        let suburb = (!!this.props.funnelData.suburb) ? this.props.funnelData.suburb : '';

        let state = (!!this.props.funnelData.state) ? this.props.funnelData.state : '';

        let loanType = (this.props.funnelData.lead_type == 'refinance') ? 'Refinance' : 'New Loan';

        let amount = (this.props.funnelData.aboutProperty.amount > 0) ? this.props.funnelData.aboutProperty.amount : 0;

        let estimated = (this.props.funnelData.aboutProperty.estimated > 0) ? this.props.funnelData.aboutProperty.estimated : 0;

        let investmentType = (this.props.funnelData.aboutProperty.investmentType == 'to-invest') ? 'Investment' : 'Owner Occupied';

        let currentLender = (this.props.funnelData.aboutProperty.currentLender) ? this.props.funnelData.aboutProperty.currentLender : '';

        let rate = (this.props.funnelData.aboutProperty.rate) ? this.props.funnelData.aboutProperty.rate : '';

        let broker = (this.props.funnelData.broker) ? this.props.funnelData.broker : '';

        let deal = (this.props.funnelData.deal) ? this.props.funnelData.deal : '';

        let postcodeId = (this.props.currentState.postcodeId) ? this.props.currentState.postcodeId : '';

        let postcodeSave = '';

        if (!deal && this.cookieGet('deal') != undefined) {
            deal = this.cookieGet('deal');
        }

        if (!broker && this.cookieGet('broker') != undefined) {
            broker = this.cookieGet('broker');
        }

        if (!this.props.currentState.postcodeId && this.cookieGet('postcode_id') != undefined) {
            postcodeId = this.cookieGet('postcode_id');
        }

        if (!!this.props.funnelData.postcode) {
            postcodeSave = this.props.funnelData.postcode;
        }

        if (!postcodeSave && !!postcode) {
            postcodeSave = postcode;
        }

        if (!postcodeSave && !!pCode) {
            postcodeSave = pCode;
        }

        if (!suburb) {
            suburb = suburbcode;
        }

        if (!!suburb) {
            leadInfo['suburb'] = suburb;
            datanew.suburb = suburb
        }
        if (!state) {
            state = sCode;
        }

        if (!!state) {
            leadInfo['state'] = state;
            datanew.state = state
        }

        // log(postcodeSave);

        // log(loanType);

        // log(amount);

        // log(estimated);

        // log(investmentType);

        // log(currentLender);

        // log(rate);
        //owner_or_investment_property

        //estimated_loan_amount

        //estimated_property_value

        //loan_type

        //current_lender

        if (!!loanType) {
            leadInfo['loan_type'] = loanType;
            datanew.loan_type = loanType
        }

        if (!!broker) {
            leadInfo['broker_id'] = broker;
            datanew.come_from_broker_id = broker
        }

        if (!!deal) {
            leadInfo['deal_id'] = deal;
            datanew.deal_id = deal
        }

        if (!!amount) {
            leadInfo['estimated_loan_amount'] = amount;
            datanew.loan_amount = amount
        }

        if (!!estimated) {
            leadInfo['estimated_property_value'] = estimated;
            datanew.estimated_property_value = estimated
        }

        if (!!investmentType) {
            leadInfo['owner_or_investment_property'] = investmentType;
            datanew.owner_or_investment_property = investmentType
        }

        if (!!currentLender) {
            leadInfo['current_lender'] = currentLender;
            datanew.current_lender = currentLender
        }

        if (!!rate) {
            leadInfo['rate'] = rate;
            datanew.rate = rate
        }

        // leadInfo['key'] = this.props.currentState.referrerKey;
        // leadInfo['first_name'] = formData.first_name;
        // leadInfo['email'] = formData.email;
        // leadInfo['mobile'] = (formData.mobile.toString().length == 9) ? 0 + formData.mobile : formData.mobile;
        // leadInfo['postcode'] = postcodeSave || 2000;
        // leadInfo['contact_day'] = formData.best_day.replace(/\//g, "-");
        // leadInfo['contact_time'] = contactTime;
        // leadInfo['timezone'] =  momentTimezone.tz.guess() || 'Australia/Sydney';
        // leadInfo['utmTags'] = this.props.currentState.utmTags;  
        // leadInfo['accept_policies'] = formData.agree; 
        // leadInfo['pageSource'] = this.props.currentState.pageSource;
        // leadInfo['codeKey'] = this.state.uuid;
        // leadInfo['code'] = this.state.mobileCode;

        datanew.referrer = this.props.currentState.referrerKey;
        datanew.first_name = formData.first_name;
        datanew.email = formData.email;
        datanew.mobile = (formData.mobile.toString().length == 9) ? 0 + formData.mobile : formData.mobile;
        datanew.postcode = postcodeSave || 9999;
        datanew.postcode_id = postcodeId;
        datanew.contact_day = formData.best_day.replace(/\//g, "-");
        datanew.contact_time = contactTime;
        datanew.timezone = momentTimezone.tz.guess() || 'Australia/Sydney';
        datanew.utm_campaign = this.props.currentState.utmTags.utm_campaign;
        datanew.utm_source = this.props.currentState.utmTags.utm_source;
        datanew.utm_medium = this.props.currentState.utmTags.utm_medium;
        datanew.utm_term = this.props.currentState.utmTags.utm_term;
        datanew.accept_policies = formData.agree;
        datanew.page_source = this.props.currentState.pageSource;
        log('login clicked')
        let data = JSON.stringify({
            // "lead": {
            //     "key": "TOztviu8rm5Fm4FYBKms+kNdeYOnHTWHJeRQ3wqnwZg=",
            //     "first_name": "Pankaj",

            //     "email": "pankaj.comsumer11@mailinator.com",
            //     "mobile": "0488826378",
            //     "postcode": "2000",
            //     "state": "NSW",
            //     "accept_policies": "Yes",
            //     "broker_id": 7,
            //     "estimated_loan_amount": 500000,
            //     "contact_day": "2018-07-23",
            //     "contact_time": "15:40",
            //     "timezone": "Australia/Sydney",
            //     "preferred_lender": "NAB"
            // }
            "lead": leadInfo
        })

        //if(this.state.ajaxCallStatus) return;

        this.setState({ ajaxCallStatus: true })

        document.getElementById("SubmitLeadButton").classList.add('loading');
        Axios.post('/v2/capture-lead', datanew, {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        ).then((response) => {
            document.getElementById("SubmitLeadButton").classList.remove('loading');
            let description = "Unable to submit the lead."
            /******************************/
            // if(response.data.status!='100')
            // {
            //     description = response.data.message;
            // }
            /*********************************/

            // if (typeof response.data.description == 'object') {
            //     description = '';
            //     for (var key in response.data.description) {
            //         if (response.data.description.hasOwnProperty(key)) {
            //             description = description + " " + response.data.description[key];
            //         }
            //     }
            // }

            // if (typeof response.data.description == 'string') {
            //     description = response.data.description;
            // }

            if (response.data.status == '100') {
                let formData = this.state.formData;
                let ref = response.data.lead;

                this.setState({
                    formData: { ...formData, ref: ref }
                })
                formData.ref = ref;
                log(formData);
                this.props.handleChangeStep('thank-you', formData);
                let cookies = new Cookies()
                cookies.remove('SaveEmail', { path: '/' })
            }
            // else if(response.data.code == '101')
            // {
            //     let description = response.data.message;
            // }
            else {
                this.setState({ ajaxCallStatus: false })
                description = response.data.message;
                this.setState({
                    submissionError: true,
                    submissionErrorMessage: description
                })
            }

        })
    }

    getSpecificDates() {

        let scope = {};
        let i = 0;
        let imax = 0;
        scope.currentDate = new Date();
        scope.CurrentYear = scope.currentDate.getFullYear();
        scope.CurrentMonth = scope.currentDate.getMonth() + 1;
        scope.CurrentDate = scope.currentDate.getDate();
        scope.Currenthours = scope.currentDate.getHours();
        scope.Currentmints = scope.currentDate.getMinutes();
        if (scope.Currenthours < 19 || (scope.Currenthours == '19' && scope.Currentmints <= '16')) {
            i = 0;
            imax = 6;
            scope.minDate = new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate());
        }
        else {
            i = 1;
            imax = 7;
            scope.minDate = new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate() + 1);
        }
        scope.MaxDate = new Date(
            scope.currentDate.getFullYear(),
            scope.currentDate.getMonth(),
            scope.currentDate.getDate() + 7);
        scope.specificDate = [];
        for (i; i <= imax; i++) {
            scope.specificDate.push(new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate() + i));
        }
        return scope.specificDate
        // log(scope.specificDate);
    }

    validateForm(event, validation = 'all', value = '') {

        log('validate form')

        if (event) {
            event.preventDefault();
        }

        this.getSpecificDates()
        log(validation)
        log(value);
        log('----')

        let formData = this.state.formData;
        let stateData = this.state;

        if (validation == 'all' || validation == 'best_day') {

            if (value) log(this.isValidDate(value.toString()));

            if (validation != 'all') { // to trigger single validation on the input

                let selectedDate = moment(value).format('YYYY/MM/DD');
                if (moment(value).isValid()) {
                    this.setBestTime(selectedDate);
                }
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'best_day': (moment(value).isValid()) ? moment(value).format('YYYY/MM/DD') : '' }
                    }
                })

            } else { // to trigger when next button is clicked
                value = formData.best_day;
            }

            if (!(!!value)) {
                let errors = this.state.errors;
                errors.best_day = "Please select a date.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.best_day;
                this.setState({
                    errors: errors
                })
            }
        }

        // best_time

        if (validation == 'all' || validation == 'best_time') {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'best_time': value }
                    }
                })
            } else {
                value = formData.best_time;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.best_time = "Please select time.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.best_time;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'email') {

            if (validation != 'all') {

                // value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'email': value }
                    }
                })
            } else {
                value = formData.email;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.email = "Please enter your email.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isEmail(value)) {
                errors.email = "Please enter a valid email";
            } else {
                let errors = this.state.errors;
                delete errors.email;
                this.setState({
                    errors: errors
                })
            }

        }

        if (validation == 'all' || validation == 'first_name') {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'first_name': value }
                    }
                })
            } else {
                value = formData.first_name;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.first_name = "Please enter first name.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.first_name;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'mobile') {

            if (validation != 'all') {
                this.setState({
                    codeVerified: false
                })
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'mobile': value }
                    }
                })
            } else {
                value = formData.mobile
            }

            let errors = this.state.errors;
            // validMobileNum

            if (!(!!value)) {
                errors.mobile = "Please enter mobile number.";
                this.setState({
                    errors: errors
                })
            }
            //  else if (validation == 'all' && this.validMobileNum(value)) {
            //     if (this.state.verifiedNumber != value) {
            //         errors.mobile = "Please verify your mobile.";
            //     }
            // } 


            else if (!this.validMobileNum(value)) {
                errors.mobile = "Please enter a valid mobile number";
            } else if (validation == 'all' && this.validMobileNum(value)) {
                if (!this.state.mobileCode && !this.state.showCodeVerification) {
                    errors.mobile = "Please verify your mobile.";
                }
            } else {
                let errors = this.state.errors;
                delete errors.mobile;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'agree') {

            if (validation != 'all') {
                value = !this.state.formData.agree;
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'agree': value }
                    }
                })
            } else {
                value = formData.agree;
            }

            let errors = this.state.errors;

            if (!value) {
                errors.agree = "This field is required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.agree;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'mobileCode') {
            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        'mobileCode': value
                    }
                })
            } else {
                value = this.state.mobileCode;
            }


            let errors = this.state.errors;

            if (!value) {
                errors.mobileCode = "This field is required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.mobileCode;
                this.setState({
                    errors: errors
                })
            }
        }

        let allErrors = this.state.errors;

        log("all errors in the book appointment..");
        log(allErrors)
        log(Object.keys(allErrors).length === 0);
        log(allErrors.constructor);
        log('----------------------------------')
        log(momentTimezone.tz.guess());
        if (Object.keys(allErrors).length === 0
            && allErrors.constructor === Object && validation == 'all') {
            log('form valid');
            var currentStep = this.props.currentState.steps;
            log("====>" + currentStep);
            this.submitLead();
            // if (currentStep.current == 'property-info-brokers') {
            //     log('===> you are now here.');
            //     this.props.handleChangeStep('enter-postcode', this.state.formData);
            // }

            // if (currentStep.current == 'property-info-deals') {
            //     this.props.handleChangeStep('deal-listing', this.state.formData);
            // }
        }


    }

    timeSelectionDropdown(starting_hour, starting_minutes) {

        //log('====>' + starting_hour + ' ' + starting_minutes);

        let i, startinghour, mrong_eveng, newOptions = [], startinghournew, mrong_evengnew;
        for (i = starting_hour; i <= 19; i++) {
            if (i > 12) {
                startinghour = i - 12;
                startinghour = '0' + startinghour;
            }
            else if (i < 10) {

                startinghour = '0' + i;
            }
            else {
                startinghour = i;
            }
            if (i >= 12)
                mrong_eveng = ' PM'
            else
                mrong_eveng = ' AM'

            let startingMinutesLatest = parseInt(starting_minutes) + 15;
            let startinghoursLatest = startinghour;
            if (startingMinutesLatest == 60) {
                startingMinutesLatest = '00';
                startinghoursLatest = parseInt(startinghour) + 1;

            }
            if (startinghoursLatest == 13) {
                startinghoursLatest = '01';

            }

            newOptions[startinghour + ':' + starting_minutes + mrong_eveng] = startinghour + ':' + starting_minutes + mrong_eveng + '-' + startinghoursLatest + ':' + startingMinutesLatest + mrong_eveng;
            if (starting_minutes == '00' && i != 20) {
                starting_minutes = '15';
                newOptions[startinghour + ':' + '15' + mrong_eveng] = startinghour + ':' + '15' + mrong_eveng + '-' + startinghour + ':' + '30' + mrong_eveng;

            }
            if (starting_minutes == '15' && i != 20) {
                starting_minutes = '30';
                newOptions[startinghour + ':' + '30' + mrong_eveng] = startinghour + ':' + '30' + mrong_eveng + '-' + startinghour + ':' + '45' + mrong_eveng;

            }

            if (starting_minutes == '30' && i != 20) {
                starting_minutes = '45';
                startinghournew = parseInt(startinghour) + 1;
                /********************/
                if (startinghournew == 13) {
                    startinghournew = 1;

                }
                if (startinghournew < 10) {
                    startinghournew = '0' + startinghournew;
                }
                if (startinghournew == 12) {
                    mrong_evengnew = 'PM'
                }
                else {
                    mrong_evengnew = mrong_eveng;
                }

                /*********************/
                newOptions[startinghour + ':' + '45' + mrong_eveng] = startinghour + ':' + '45' + mrong_eveng + '-' + startinghournew + ':' + '00' + mrong_evengnew;

            }

            if (starting_minutes == '45' && i != 20) {
                starting_minutes = '00';
                //newOptions[startinghour + ':' + '00' + mrong_eveng] = startinghour + ':'+ '00' + mrong_eveng;
            }
        }

        // this.setState({
        //     ar_best_time: allTimes
        // })
        let allTimes = []
        for (let key in newOptions) {
            if (newOptions.hasOwnProperty(key)) {
                let t = {};
                t['key'] = key;
                t['value'] = newOptions[key];
                allTimes.push(t)
                //log(key + " -> " + newOptions[key]);
            }
        }
        this.setState({
            ar_best_time: allTimes
        })
    }

    validMobileNum(MobNumber) {

        if (MobNumber.length == 9) {
            if (MobNumber.slice(0, 1) == 4) {
                return true;
            } else {
                return false;
            }
        } else if (MobNumber.length == 10) {
            if (MobNumber.slice(0, 2) == '04') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    isValidDate(dateString) {
        let regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        let d = new Date(dateString);
        if (Number.isNaN(d.getTime())) return false; // Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }

    setBestTime(selected_date) {

        let scope = {};

        scope.currentDate = new Date();
        scope.CurrentYear = scope.currentDate.getFullYear();
        scope.CurrentMonth = scope.currentDate.getMonth() + 1;
        scope.CurrentDate = scope.currentDate.getDate();
        scope.Currenthours = scope.currentDate.getHours();
        scope.Currentmints = scope.currentDate.getMinutes();

        let currentDateTime = new Date();
        selected_date = new Date(selected_date);
        let formatttedCurrentSelectedDate = selected_date.getFullYear() + '/' + (selected_date.getMonth() + 1) + '/' + selected_date.getDate();
        let formatttedCurrentDate = scope.CurrentYear + '/' + scope.CurrentMonth + '/' + scope.CurrentDate;

        let currentD = new Date();
        let startHappyHourD = new Date();
        startHappyHourD.setHours(7, 30, 0); // 7:30 am
        let endHappyHourD = new Date();
        endHappyHourD.setHours(19, 30, 0); // 7.30 pm


        let ctime = scope.Currenthours + ":" + scope.Currentmints;
        /*log(ctime);
        log(formatttedCurrentDate);
        log(formatttedCurrentSelectedDate); */
        let starting_minutes;
        let starting_hour;
        if (formatttedCurrentDate == formatttedCurrentSelectedDate && currentD >= startHappyHourD && currentD < endHappyHourD) {
            currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
            starting_minutes = currentDateTime.getMinutes();
            starting_hour = currentDateTime.getHours();
            //log('hh'+starting_minutes);
            if (starting_minutes < '10')
                starting_minutes = '0' + starting_minutes;
            if (starting_minutes > '00' && starting_minutes <= '15')
                starting_minutes = '15';
            if (starting_minutes > '15' && starting_minutes <= '30')
                starting_minutes = '30';
            if (starting_minutes > '30' && starting_minutes <= '45')
                starting_minutes = '45';
            if (starting_minutes > '45') {
                starting_hour = starting_hour + 1;
                starting_minutes = '00';
            }

        }
        else {
            starting_hour = 8;
            starting_minutes = '00';
        }
        // log('ff'+ starting_hour+' '+starting_minutes);

        this.timeSelectionDropdown(starting_hour, starting_minutes);
    }


    sendMobileCode(event, type = '') {

        let mobile = this.state.formData.mobile;
        this.validateForm('', 'mobile', mobile);
        if (!!mobile && this.validMobileNum(mobile)) {

            let data = {
                phone: mobile
            }
            document.getElementById("VerifyMobileButton").classList.add('loading');

            this.setState({ submissionErrorMessage: '', mobileCode: '' });
            Axios.post('/send-sms-code-v3', data,
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                    }
                }).then((response) => {
                    log(response);
                    document.getElementById("VerifyMobileButton").classList.remove('loading');
                    if (response.data.status == "100") {
                        this.setState({
                            uuid: response.data.message.id,
                            showCodeVerification: true
                        })
                    } else {

                        let errors = this.state.errors;
                        let errorMessage = '';
                        // if(response.data.status=='blocked')
                        // {
                        //     errorMessage="Due to too many attempts, this number has been blocked for one day.";
                        // }else if(response.data.status=='exists'){
                        //      errorMessage='We already have your details. Please <a href="/consumer-login">click here</a> to login.';
                        // }else if(response.data.status=='bypass'){
                        //         this.setState({
                        //         verifiedNumber: mobile,
                        //         showCodeVerification: false,
                        //         codeVerified: true
                        //     })
                        // }else{
                        //     errorMessage="Something went wrong.";
                        // }
                        // errors.mobile = errorMessage ;
                        // this.setState({
                        //     errors: errors
                        // })
                        if (response.data.status != '100') {
                            errorMessage = response.data.message;
                        }
                        else if (response.data.status == '103') {
                            this.setState({
                                verifiedNumber: mobile,
                                showCodeVerification: false,
                                codeVerified: true
                            })
                        }
                        errors.mobile = errorMessage;
                        this.setState({
                            errors: errors
                        })

                    }
                    log(response);
                })
        }
    }

    verifyMobileCode(event, code = '') {
        log("Im inside mobile code verify");
        log(arguments)
        let uuid = this.state.uuid;
        let mobile = this.state.formData.mobile;
        let mobileCode = this.state.mobileCode;
        this.validateForm('', 'mobileCode', mobileCode);
        if (!!mobileCode && !!mobile && this.validMobileNum(mobile)) {
            Axios.post('/validate-mobile-code-blog', 'mobilecode=' + mobileCode + '&uuid=' + uuid,
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                    }
                }).then((response) => {
                    if (response.data.status == "pass") {
                        this.setState({
                            verifiedNumber: mobile,
                            showCodeVerification: false,
                            codeVerified: true
                        })
                    } else {
                        let errors = this.state.errors;
                        errors.mobileCode = response.data.mobileCode;
                        this.setState({
                            errors: errors
                        })
                    }
                    log(response);
                })
        }
    }

    changeNumber(event) {
        this.setState({
            showCodeVerification: false,
            codeVerified: false,
            formData: { ...this.state.formData, 'mobile': '' },
            mobileCode: '',
            submissionErrorMessage: ''
        })
    }

    componentDidMount() {

        document.getElementsByClassName(Styles.slectdate)[0].readOnly = true;

        log(this.cookieGet('SaveEmail'))

        if (this.cookieGet('SaveEmail') != undefined) {

            let savedEmail = this.cookieGet('SaveEmail')
            this.setState({

                formData: { ...this.state.formData, 'email': savedEmail },

            })
        }
        else {

            this.setState({

                formData: { ...this.state.formData, 'email': '' },

            })
        }


    }

    render() {

        let activeClass = '', agreement;
        agreement = this.state.formData.agree;

        if (agreement == true) {
            activeClass = Styles.activeOption;
        }

        return (
            <Fragment>
                <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor)}>
                    <FunnerProgressHeader {...this.props} active={'Appointment'} />
                    <section className={cx(Styles.loandealStepSection)}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                    <div className={'row'}>
                                        <div className={cx('col-lg-7', Styles.loandealStepLeft, Styles.bookAppoinmntLeft)}>
                                            <div className={cx(Styles.loandealStepLeftcontnt)}>
                                                <div className={cx('card', Styles.card)}>
                                                    <div className={cx('card-body', Styles.cardBody)}>
                                                        <h2 className={cx(Styles.sectionTitle)}>Choose when you <br /> would like to be <br /> contacted</h2>
                                                        <div className={cx('form-fields', Styles.formFields)}>
                                                            <div className={cx(Styles.bookAppoinmntFields)}>
                                                                <div className={cx('form-group', Styles.formGroup, Styles.slectdateField)}>
                                                                    {/* <DatePicker
                                                                    className={cx('form-control', Styles.slectdate)}
                                                                    placeholderText=""
                                                                    onChange={() => {
                                                                        log('asdf');
                                                                    }}
                                                                /> */}
                                                                    <label>What is the best day to contact you?</label>
                                                                    {/* <input type="text" className={cx('form-control', Styles.slectdate)} placeholder="Thu 20 Dec" onChange={((event) => {
                                                                    this.validateForm(event, 'best_day', event.target.value);
                                                                })} /> */}
                                                                    <div className={cx('form-control', Styles.formControl)} >
                                                                        <DatePicker
                                                                            className={Styles.slectdate}
                                                                            placeholderText="2019/01/31"
                                                                            dateFormat="yyyy/MM/d"
                                                                            selected={(this.state.formData.best_day) ? new Date(this.state.formData.best_day) : null}
                                                                            includeDates={this.getSpecificDates()}
                                                                            onChange={((value) => {
                                                                                log('date selected')
                                                                                log(value)
                                                                                this.validateForm('', 'best_day', value);
                                                                            })}

                                                                        />
                                                                    </div>
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.best_day) ? 'block' : 'none' }}>
                                                                        {this.state.errors.best_day}
                                                                    </div>
                                                                </div>
                                                                {/* {log(this.state.ar_best_time)} */}
                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    {/* {this.state.ar_best_time.map((time) => (
                                                                    <li key={time}>
                                                                        {time}
                                                                    </li>
                                                                ))} */}
                                                                    <label>What is the best time?</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.slecttime)} onChange={((event) => {
                                                                        this.validateForm(event, 'best_time', event.target.value);
                                                                    })}>
                                                                        <option value="">Select Time</option>
                                                                        {this.state.ar_best_time.map((time) => {
                                                                            return (
                                                                                <option key={time.key}>{time.value}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {/* <input type="text" className={cx('form-control', Styles.slecttime)} placeholder="16:00" onChange={((event) => {
                                                                    this.validateForm(event, 'best_time', event.target.value);
                                                                })} /> */}
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.best_time) ? 'block' : 'none' }}>
                                                                        {this.state.errors.best_time}
                                                                    </div>
                                                                </div>

                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    <label>Your first name</label>
                                                                    <input type="text" className={cx('form-control', Styles.formControl)} placeholder="" onChange={((event) => {
                                                                        this.validateForm(event, 'first_name', event.target.value);
                                                                    })} />
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.first_name) ? 'block' : 'none' }}>
                                                                        {this.state.errors.first_name}
                                                                    </div>
                                                                </div>

                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    <label>Your email</label>
                                                                    <input type="text" className={cx('form-control', Styles.formControl)} value={this.state.formData.email} placeholder="example@example.com" onChange={((event) => {
                                                                        this.validateForm(event, 'email', event.target.value);
                                                                    })} />
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.email) ? 'block' : 'none' }}>
                                                                        {this.state.errors.email}
                                                                    </div>
                                                                </div>
                                                                <div className={cx('form-group', Styles.formGroup, Styles.mobfield)}>
                                                                    <label>Your mobile number</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>+61</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="" onChange={((event) => {
                                                                            this.validateForm(event, 'mobile', event.target.value);
                                                                        })} value={this.state.formData.mobile} style={{ backgroundColor: (this.state.showCodeVerification || this.state.codeVerified) ? '#ffffff' : '' }} disabled={(this.state.showCodeVerification || this.state.codeVerified) ? "disabled" : ""} />
                                                                        <div id="VerifyMobileButton" className={cx('input-group-append', Styles.inputGroupAppend)}>
                                                                            <button className={cx('btn', Styles.blueBtn, Styles.btn, Styles.verifyMobile)} type="button" onClick={(event) => {
                                                                                this.sendMobileCode(event)
                                                                            }} disabled={(this.state.showCodeVerification || this.state.codeVerified) ? "disabled" : ""} >Verify</button>
                                                                        </div>

                                                                        {this.state.showCodeVerification ?

                                                                            <div className={Styles.codeaction}>
                                                                                A 6-Digit code has been sent to your mobile
                                                                                {<span className={(Styles.codeactionLinks)}> <a href="javascript://" onClick={(event) => { this.sendMobileCode(event) }}>Send Again</a> or <a href="javascript://" onClick={(event) => { this.changeNumber(event) }}>Change Number</a>
                                                                                </span>}
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }

                                                                    </div>

                                                                    <div className={'message-succes'} style={{ display: (this.state.codeVerified) ? 'block' : 'none' }}>
                                                                        Mobile number is verified.
                                                                    </div>


                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.mobile) ? 'block' : 'none' }} dangerouslySetInnerHTML={{ __html: this.state.errors.mobile }}>

                                                                    </div>
                                                                </div>
                                                                {/* showCodeVerification */}
                                                                <div className={cx('form-group', Styles.formGroup, Styles.veryfymobfield)} style={{ display: (!!this.state.showCodeVerification) ? 'block' : 'none' }}>
                                                                    <label>6-Digit Code</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} placeholder="" onChange={((event) => {
                                                                            this.validateForm(event, 'mobileCode', event.target.value);
                                                                        })} value={this.state.mobileCode} />


                                                                    </div>


                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.mobileCode) ? 'block' : 'none' }}>
                                                                        {this.state.errors.mobileCode}
                                                                    </div>
                                                                </div>
                                                                <div className={cx('form-group', Styles.formGroup, Styles.agreeField)}>
                                                                    <div className={cx(Styles.customCheckmark)}>
                                                                        <span className={cx(Styles.checkmark, activeClass)} onClick={((event) => {
                                                                            this.validateForm(null, 'agree', 'on');
                                                                        })}>
                                                                            <i className={cx('fa', Styles.faCheck)} aria-hidden="true">
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </i>
                                                                        </span>
                                                                        <input type="checkbox" name="" value="true" />
                                                                        <span className={cx(Styles.readingText)}>
                                                                            I agree to HashChing's <a href="/privacy" target="_blank">Privacy Policy</a> and <a href="/terms-conditions" target="_blank">Terms of use</a>. Please arrange for a HashChing partner broker to
                                                                            <nobr> contact me.</nobr>
                                                                        </span>
                                                                        <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.agree) ? 'block' : 'none' }}>
                                                                            {this.state.errors.agree}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="SubmitLeadButton" className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.submissionError) ? 'block' : 'none' }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: this.state.submissionErrorMessage }}></span>                                         </div>
                                                                    <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="#" onClick={this.validateForm}>Confirm phone booking</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-lg-5', Styles.loandealStepRight, Styles.contctbrokrRight)}>
                                            <div className={cx(Styles.loandealStepRightcontnt, Styles.contctbrokrContnt)}>
                                                <div className={Styles.contctbrokrContntInnr}>
                                                    <BookAppointmentSidebar {...this.props} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <StickyBottomBar {...this.props} hideSkip={true}> </StickyBottomBar>
                </section>
            </Fragment>
        )
    }
}

const ThankYou = (props) => {

    log(props.funnelData.bookAppointment);

    let date = props.funnelData.bookAppointment.best_day;

    let time = props.funnelData.bookAppointment.best_time.split("-")[0];

    let formatedDate = moment(date + ' ' + time).format("dddd Do MMMM");

    // log(moment.utc(date + ' ' + time))

    let timeFrom = moment(date + ' ' + time).utc().format("YYYYMMDDTHHmm00") + 'Z';

    let timeTo = moment(date + ' ' + props.funnelData.bookAppointment.best_time.split("-")[1]).utc().format("YYYYMMDDTHHmm00") + 'Z';

    log(timeFrom, "===> time from");

    log(timeTo, "===> time to");


    let addToCalenderLink = 'https://calendar.google.com/calendar/r/eventedit?text=Broker+Appointment&dates=' + timeFrom + '/' + timeTo + '&sf=true&output=xml'


    return (
        <Fragment>
            <section className={cx(Styles.loandealStepSection)}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-xl-5 col-lg-6 col-md-7 col-sm-12', 'mr-auto', 'ml-auto', Styles.othrstepSection)}>

                            <div className={cx(Styles.thnkuSection, 'text-center')}>
                                <div className={cx(Styles.sectioncontnt)}>
                                    <img src={tickImage} alt="tick-img" />
                                    <h3 className={cx('text-center', Styles.sectionHeding)}>Thank you</h3>
                                    <p className={cx(Styles.dscrption)}>You are confirmed for <br /><strong>{time} </strong>on <strong>{formatedDate}</strong></p>
                                    <a className={cx(Styles.addCalndrLink)} href={addToCalenderLink} target="_blank">Add to Calendar</a>
                                    <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                        <a className={cx('btn', Styles.blueBtn, Styles.continuBtn)} href="javascript:void(0);" onClick={() => {
                                            props.handleChangeStep('dashboard');
                                        }}>Continue to Dashboard</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

const WelComeToDashboard = (props) => {

    log(props.funnelData.bookAppointment);

    let ref = props.funnelData.bookAppointment.ref;

    return (
        <Fragment>
            <section className={cx(Styles.loandealStepSection)}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-xl-8 col-lg-12', 'mr-auto', 'ml-auto', Styles.dashbordStepSection)}>
                            <div className={cx('text-center', Styles.headrLogo)}>
                                <a href={'/'}>
                                    <img className={cx(Styles.logoHashching)} src={logo} alt="HashChing home loans" />
                                </a>
                            </div>
                            <h3 className={cx('text-center', Styles.sectionTitle)}>Welcome to your dashboard. </h3>
                            <h4 className={cx('text-center', Styles.sectionSubTitle)}>
                                Follow up your application and communications in this <nobr>central place.</nobr></h4>
                            <div className={cx(Styles.dashbordStepContnt)}>
                                <div className={cx('row')}>
                                    <div className={cx('col-md-6', Styles.sectionLeft)}>
                                        <figure className={cx(Styles.figure)}>
                                            <img className={cx(Styles.sectionimg, Styles.dsktopimg)} src={SecuredappDesktop} alt="securedapp" />
                                            <img className={cx(Styles.sectionimg, Styles.mobimg)} src={SecuredappMobile} alt="securedapp" />

                                        </figure>
                                    </div>
                                    <div className={cx('col-md-6', Styles.sectionRight)}>
                                        <div className={cx(Styles.sectionRightInnr)}>
                                            <ul className={cx(Styles.stepsListng)}>
                                                <li>
                                                    <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                        <g fill="#1E7B97" fillRule="evenodd">
                                                            <path d="M8.278 6.386l2.25 6.366 2.833-9.264 2.635 7.465h5.864l.001-.057c0-5.987-4.853-10.84-10.84-10.84C5.038.056.185 4.91.185 10.895v.057h5.93l2.164-4.566z" />
                                                            <path d="M13.478 9.04l-2.832 9.262-2.602-7.365-.831 1.753H.333c.856 5.13 5.314 9.043 10.688 9.043 5.375 0 9.833-3.913 10.688-9.044h-6.943L13.478 9.04z" />
                                                        </g>
                                                    </svg></span>
                                                    <span className={cx(Styles.stepListingTxt)}>Keep track of your application</span>
                                                </li>
                                                <li>
                                                    <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
                                                        <path fill="#1E7B97" fillRule="evenodd" d="M14.04 7.77V5.787c0-.514-.076-1.847-.782-3.12C12.583 1.45 11.171 0 8.273 0S3.962 1.451 3.288 2.668c-.706 1.273-.783 2.606-.783 3.12v1.981H1.196C.536 7.77 0 8.305 0 8.965v9.519c0 .66.536 1.196 1.196 1.196h14.575c.66 0 1.197-.535 1.197-1.196v-9.52c0-.66-.536-1.195-1.197-1.195h-1.73zM8.275 2.314c3.115 0 3.453 2.43 3.453 3.473v1.981H4.822V5.788c-.001-1.044.335-3.473 3.452-3.473zM6.612 17.4l-2.463-2.774 1.404-1.236 1.081 1.226 4.808-4.568 1.378 1.61L6.612 17.4z" />
                                                    </svg></span>
                                                    <span className={cx(Styles.stepListingTxt)}>Upload your documents securely</span>
                                                </li>
                                                <li>
                                                    <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19">
                                                        <g fill="#1E7B97" fillRule="evenodd">
                                                            <path d="M22.329 10.773c1.138-1.138 1.82-2.706 1.82-4.451C24.15 2.832 21.318 0 17.802 0c-1.265 0-2.428.354-3.414 1.011a9.494 9.494 0 0 1 3.64 7.51c0 1.492-.328 2.883-.96 4.123.227.025.48.05.708.05.582 0 1.138-.1 1.694-.227.835.582 1.871 1.214 2.958 1.644.658.252 1.265-.456.886-1.038-.505-.834-.783-1.643-.985-2.3z" />
                                                            <path d="M8.42.203A8.299 8.299 0 0 0 .127 8.497c0 2.276.911 4.325 2.403 5.816a11.352 11.352 0 0 1-1.315 2.959c-.506.784.303 1.72 1.138 1.365 1.416-.556 2.782-1.391 3.894-2.15a7.96 7.96 0 0 0 2.2.303 8.299 8.299 0 0 0 8.294-8.294c0-4.577-3.717-8.294-8.32-8.294zM4.806 7.409c.606 0 1.087.48 1.087 1.088a1.08 1.08 0 0 1-1.087 1.088 1.08 1.08 0 0 1-1.088-1.088 1.08 1.08 0 0 1 1.088-1.088zM8.42 9.584a1.08 1.08 0 0 1-1.088-1.088c0-.606.48-1.088 1.088-1.088.606 0 1.088.48 1.088 1.088A1.08 1.08 0 0 1 8.42 9.584zm3.64 0a1.08 1.08 0 0 1-1.087-1.088c0-.606.48-1.088 1.088-1.088.606 0 1.087.48 1.087 1.088 0 .608-.48 1.088-1.087 1.088z" />
                                                        </g>
                                                    </svg></span>
                                                    <span className={cx(Styles.stepListingTxt)}>Instantly message your broker or our support team</span>
                                                </li>
                                                <li>
                                                    <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                        <g fill="none" fillRule="evenodd">
                                                            <path fill="#1E7B97" d="M10.414.577a.796.796 0 0 0-.479-.162.915.915 0 0 0-.479.162L.43 7.681c-.254.251-.432.794-.17 1.128.26.334.81.38 1.128.097l.756-.572V16.778c0 .43.349.78.78.78h14.025a.78.78 0 0 0 .78-.78V8.333l.737.57c.317.282.878.246 1.128-.097.219-.301.165-.877-.17-1.128l-9.009-7.1z" />
                                                            <path fill="#FFF" d="M9.87 6.753c.693 0 1.266.493 1.299 1.103v.715H8.57v-.715c.033-.61.606-1.103 1.3-1.103m-.513 4.11c.039-.24.22-.438.45-.47.314-.04.582.23.582.564a.55.55 0 0 1-.249.479v.74c0 .166-.124.292-.267.292-.153 0-.267-.136-.267-.292v-.74c-.182-.104-.287-.323-.249-.573m2.612-2.049v-.71c0-1.17-.934-2.13-2.095-2.13-1.15 0-2.095.95-2.095 2.13v.71c-.565.01-1.027.48-1.027 1.055v3.362c0 .585.462 1.055 1.037 1.055h4.16c.575 0 1.037-.47 1.037-1.055V9.87c0-.574-.452-1.044-1.017-1.055" />
                                                        </g>
                                                    </svg></span>
                                                    <span className={cx(Styles.stepListingTxt)}>Get discounted conveyancing and home insurance </span>
                                                </li>

                                            </ul>

                                        </div>

                                    </div>
                                    <div className={cx('text-center col-sm-12', Styles.fieldsubmtBtn)}>
                                        <a className={cx('btn', Styles.blueBtn, Styles.continuebutton)} href={'/thanks-home-loan-enquiry/' + ref}>Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    )
}

class SaveYourProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: '',
            },
            errors: {}
        }

        this.validateForm = this.validateForm.bind(this);

    }

    validateForm(event, validation = 'all', value = '') {
        log('validate form')
        if (event) {
            event.preventDefault();
        }

        let formData = this.state.formData;
        let stateData = this.state;

        if (validation == 'all' || validation == 'email') {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'email': value }
                    }
                })
            } else {
                value = formData.email;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.email = "Please enter your email.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isEmail(value)) {
                errors.email = "Please enter a valid email";
            } else {
                let errors = this.state.errors;
                delete errors.email;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' && validator.isEmail(value)) {
            let errors = this.state.errors;
            log("validate all called..")
            Axios.post('/gatsby-api/save-for-later', {
                email: value,
                type: 'funnel',
                data_info: JSON.stringify(this.props.funnelData)
            })
                .then((response) => {
                    log()
                    if (response.data.status == '100') {
                        this.props.handleSaveYourProgress(value)
                    }
                    else {
                        errors.email = "Unable to save your details";
                    }
                })
                .catch((error) => {
                    errors.email = "Unable to save your details";
                });
        }
    }

    render() {
        return (
            <Fragment>
                <section className={cx(Styles.getstartedStepsPage)}>

                    <section className={cx(Styles.loandealStepSection)}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={cx('col-lg-5 col-md-7 col-sm-12 mr-auto ml-auto', Styles.othrstepSection)}>
                                    <div className={cx(Styles.entrEmailSection)}>
                                        <div className={cx(Styles.sectioncontnt)}>
                                            <h3 className={cx('text-center', Styles.sectionTitle)}>Save your progress and get <br /> all the info sent to you</h3>
                                            <div className={cx('form-group', Styles.formGroup)}>
                                                <label>Enter your email</label>
                                                <input type="text" className={cx('form-control', Styles.formControl)} placeholder="example@example.com" onChange={((event) => {
                                                    this.validateForm(event, 'email', event.target.value);
                                                })} />
                                                <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.email) ? 'block' : 'none' }}>
                                                    {this.state.errors.email}
                                                </div>
                                            </div>

                                            <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="" onClick={this.validateForm}>Next</a>
                                            </div>
                                            <div className={cx(Styles.sectionOthrDescrption)}>
                                                <p>The service is free of charge and you&apos;re not
                                                    locked in anything.
                                                </p>
                                                <p>
                                                    Your data is safe with us. Don’t worry, no spam or selling it to other affiliates
                                                </p>
                                                <p>
                                                    We may send you relevent information to market trends, news and rates updates so you can make informed decisions.
                                                </p>
                                            </div>
                                            <a className={cx(Styles.skipstpLink)} href="#" onClick={() => {
                                                this.props.skipBtnHandler('save-your-progress')
                                            }} >Skip this step</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Fragment>

        )
    }
}

const SaveYourProgress1 = (props) => {
    return (
        <Fragment>
            <section className={cx(Styles.getstartedStepsPage)}>
                <FunnerProgressHeader {...this.props} active={'The property'} />
                <section className={cx(Styles.loandealStepSection)}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-lg-5 col-md-7 col-sm-12 mr-auto ml-auto', Styles.othrstepSection)}>
                                <div className={cx(Styles.entrEmailSection)}>
                                    <div className={cx(Styles.sectioncontnt)}>
                                        <h3 className={cx('text-center', Styles.sectionTitle)}>Save your progress and get <br /> all the info sent to you</h3>
                                        <div className={cx('form-group', Styles.formGroup)}>
                                            <label>Enter your email</label>
                                            <input type="text" className={cx('form-control', Styles.formControl)} placeholder="example@example.com" />
                                        </div>
                                        <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                            <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="">Next</a>
                                        </div>
                                        <div className={cx(Styles.sectionOthrDescrption)}>
                                            <p>The service is free of charge and you&apos;re not
                                                locked in anything.
                                            </p>
                                            <p>
                                                Your data is safe with us. Don’t worry, no spam or selling it to other affiliates
                                            </p>
                                            <p>
                                                We may send you relevent information to market trends, news and rates updates so you can make informed decisions.
                                            </p>
                                        </div>
                                        <a className={cx(Styles.skipstpLink)} href="#" onClick={() => {
                                            props.skipBtnHandler('save-your-progress')
                                        }}>Skip this step</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </Fragment>
    )
}

const Calculator = (props) => {
    return (
        <Fragment>
            <BorrowingCalculator {...props} />
            <StickyBottomBar {...props} hideSkip={true}> </StickyBottomBar>
        </Fragment>
    )
}

const FunnelDeals = (props) => {

    let cookies = new Cookies();

    let broker = cookies.get('broker');

    let btnText = 'Skip to find a broker';

    if (broker != undefined) {
        btnText = 'Skip and Continue'
    }


    return (
        <Fragment>
            <div className={Styles.funnelDealListingsection}>
                <FunnerProgressHeader {...props} active={'Your rates'} />
                <div className={Styles.funnelDealListing}>
                    <DealList onDealClickHandler={props.dealClickHandler} {...props} />
                </div>
                <StickyBottomBar {...props} skipBtnText={btnText} skipFrom={'deal-listing'}></StickyBottomBar>
            </div>
        </Fragment>
    )
}

const FunnelDealDetails = (props) => {

    let cookies = new Cookies();

    let broker = cookies.get('broker');

    let btnText = 'Skip to find a broker';

    if (broker != undefined) {
        btnText = 'Skip and Continue'
    }

    return (
        <Fragment>
            <FunnerProgressHeader {...props} active={'Your rates'} />
            <DealDetailSection dealID={props.dealID} {...props} />
            <StickyBottomBar {...props} skipBtnText={btnText} skipFrom={'deal-details'}></StickyBottomBar>
        </Fragment>
    )
}


class FunnelBrokers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            postcode: props.funnelData.postcode,
            suburb: props.funnelData.suburb,
            state: props.funnelData.state,
        };
    }


    render() {
        log(this.state.postcode)
        return (
            <Fragment>
                <div className={Styles.funnelbrokrListingsection}>
                    <FunnerProgressHeader {...this.props} active={'Your broker'} />
                    <div className={Styles.funnelbrokrListing}>
                        <BrokerListing postcode={this.state.postcode} suburb={this.state.suburb} state={this.state.state} handleBrokerClick={this.props.handleBrokerClick} />
                    </div>
                    <StickyBottomBar {...this.props} skipBtnText={'Skip & choose broker for me'} skipFrom={'broker-finder'}></StickyBottomBar>
                </div>
            </Fragment>
        );
    }
}

const FunnelBrokerDetails = (props) => {
    return (
        <Fragment>
            <div className={Styles.funnelbrokrDetailsection}>
                <FunnerProgressHeader {...props} active={'Your broker'} />
                <div className={Styles.funnelbrokrDetailMain}>
                    <BrokerDetailSection brokerId={props.brokerId} {...props}></BrokerDetailSection>
                </div>
                <StickyBottomBar {...props} skipBtnText={'Skip & choose broker for me'} skipFrom={'broker-details'}></StickyBottomBar>
            </div>
        </Fragment>
    )
}

const FunnelSaveProgress = (props) => {

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col-md-12'}>

                    <div style={{ margin: '20px auto', width: '300px', padding: '10px' }}>
                        <form>
                            <div className={"form-group"}>
                                <label>Enter your email</label>
                                <input className={"form-control"} defaultValue={props.email} id="progress-email" onChange={props.handleClick} />
                                <div id="progress-email-error" className={"error"}></div>
                            </div>
                            <div className={"form-group"} >
                                <a className={"btn btn-primary"} onClick={props.handleClick}>Save Progress</a>
                            </div>
                            <div className={"form-group"}>
                                <a id="hash-btn-skip-progress" className={"btn"} onClick={props.handleClick}>Skip</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

class FunnelChooseBroker extends React.Component {
    constructor(props) {
        super(props);

        this.myRefChooseBroker = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, this.myRefChooseBroker.current.offsetTop)

    }

    render() {
        let cookies = new Cookies()

        log("===> postcode check")

        log(cookies.get('postcode', { 'path': '/' }) == undefined && cookies.get('pCode', { 'path': '/' }) == undefined)

        let hideSkipButton = false;

        if (cookies.get('postcode', { 'path': '/' }) == undefined && cookies.get('pCode', { 'path': '/' }) == undefined) {
            hideSkipButton = true;
        }

        return (
            <Fragment >
                <section ref={this.myRefChooseBroker}>
                    <FunnerProgressHeader {...this.props} active={'Your broker'} />
                    <FunnelChoosePostcode {...this.props} />
                    <StickyBottomBar {...this.props} skipBtnText={'Skip & choose a broker for me'} skipFrom={'enter-postcode'} hideSkip={true}></StickyBottomBar>
                </section>
            </Fragment>
        )
    }
}



class FunnelChoosePostcode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            userInput: "",
            suggestionClass: 'closeSuggestionClass',
            message: 'Please select postcode from list',
            requestpostcode: '',
            flag: false,
            errorMsgClass: 'errorMsgHide',
            postcodeId: ''
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        // this.onClickSearch = this.onClickSearch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

    }



    onChange(event) {
        let userInput = event.currentTarget.value;
        let postcode = [];
        let _this = this;
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        this.state.flag = false;
        log(userInput);
        if (!userInput) {
            log('empty');
            this.setState({
                suggestions: [],
                suggestionClass: 'closeSuggestionClass'
            });
        }

        if (userInput.length >= 3) {
            if (parseInt(userInput) != 0 || parseInt(userInput) == 'NaN') {
                const url = `/suburblookup?suburblookup=${userInput}`
                if (this.state.requestpostcode) {
                    source.cancel();
                }

                this.state.requestpostcode = Axios.get(url, {
                    cancelToken: source.token
                }).catch(function (error) {
                    if (Axios.isCancel(error)) {
                        log('Request canceled');
                    } else {
                        console.error(error);
                    }
                }).then(function (response) {
                    if (response && userInput) {
                        postcode = response.data.postcodes;
                        if (postcode.length) {
                            _this.setState({
                                suggestions: postcode,
                                suggestionClass: 'openSuggestionClass',
                                message: 'Please select postcode from list',
                                requestpostcode: null,
                            });
                        } else {

                            _this.setState({
                                suggestions: postcode,
                                message: 'Please enter a valid postcode',
                                requestpostcode: null,
                                suggestionClass: 'openSuggestionClass',
                            });
                        }
                    } else {
                        console.error('no response');
                    }
                })
            }
        }

        if (userInput.length > 0) {
            _this.setState({
                userInput: event.currentTarget.value,
                errorMsgClass: 'errorMsgHide'
            });
        } else {
            _this.setState({
                userInput: event.currentTarget.value,
                suggestionClass: 'closeSuggestionClass',
                errorMsgClass: 'errorMsgHide'
            });
        }

    }

    onClick(event, id) {

        let _this = this;
        _this.setState({
            userInput: event.currentTarget.innerText,
            suggestionClass: 'closeSuggestionClass',
            postcodeId: id,
            flag: true
        });
        if (!!event.currentTarget.innerText) {
            var postcodes = event.currentTarget.innerText.split(',');
            const cookies = new Cookies();
            postcodes[3] = id;
            cookies.set('postcode_id', id, { path: '/' });
            cookies.set('postcode', postcodes[0], { path: '/' });
            cookies.set('locality', postcodes[1], { path: '/' });
            cookies.set('statecode', postcodes[2], { path: '/' });
            this.props.handleChangeStep('broker-finder', postcodes);
        }
    }


    onKeyDown() {
        let _this = this;
        _this.setState({
            suggestions: [],
            message: 'Please enter a valid postcode.',
            suggestionClass: 'closeSuggestionClass',
        });
    }

    render() {

        const suggestionClass = this.state.suggestionClass;
        const suggestionMessage = this.state.message;
        const errorMessage = this.state.errorMsgClass;

        return (
            <Fragment>
                <section className={cx("toplocl-brokr-section", "text-center")} >
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-sm-12 toplocl-brokr-contnt"}>
                                <h2 className={"section-title"}>Search in your area for top <nobr>local brokers </nobr></h2>
                                <div className={"selectsuburb"}>
                                    <input type="text" id="" name="" placeholder="Enter your suburb or postcode" className={"selectsuburb-field"} onChange={this.onChange} onKeyDown={this.onKeyDown} value={this.state.userInput} />
                                    <p className={cx(classes.errorMsg, classes[errorMessage])}>{suggestionMessage}</p>
                                    <div className={cx(classes.suburbContainer, classes[suggestionClass])}>
                                        <div className={classes.autoSuggestCodes}>
                                            <div className={classes.arrowup}></div>
                                            <p className={classes.selectPostcodeHeding}>
                                                {suggestionMessage}
                                            </p>
                                            <ul>
                                                {
                                                    this.state.suggestions.map((postcode) =>
                                                        <li key={postcode.id} onClick={(event) => { this.onClick(event, postcode.id) }}>
                                                            <a href="javascript://"  >{`${postcode.postcode}, ${postcode.locality}, ${postcode.statecode}`} </a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={cx('col-sm-12', Styles.chooseBrokrinfo)}>
                                <div className={Styles.chooseBrokrimg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="73" viewBox="0 0 62 73">
                                        <g fill="none" fillRule="evenodd">
                                            <path fill="#0AB5DD" d="M52.543 57.328l-5.237 4.394-.169-.201a3.418 3.418 0 1 1 5.237-4.395l.17.202zm.71.846l7.505 8.944a3.418 3.418 0 1 1-5.237 4.394l-7.505-8.944 5.237-4.394z" />
                                            <path fill="#1A3E61" d="M30.62 41.261a28.354 28.354 0 0 1 20.537 8.805l.93.977c-7.072 5.958-11.781 8.937-14.129 8.937-1.985 0-.819 1.904-14.933 0-1.717-.232-6.362-3.21-13.933-8.937l.961-1.004a28.48 28.48 0 0 1 20.566-8.778zM21.07 25.766c-.936-1.387-1.484-4.581-1.352-6.447.132-1.866.727-3.317 2.1-4.487-.483-.609-1.124-.853-1.373-.853 1.373-3.197 12.647-9.488 17.59-.21 1-.251 1.322-.21 1.486 0 .164.21 1.854 1.399 1.542 9.49-.545 1.168-1.256 1.492-1.292 2.93-.036 1.439-.377 5.023-.905 6.889-.528 1.866-4.913 6.028-7.089 6.028s-2.977.231-4.54-.745c-1.563-.976-4.102-2.429-5.08-4.736-.979-2.307-.816-5.23-1.087-7.86z" />
                                            <path fill="#0AB5DD" d="M30.713 62.774C13.823 62.774.13 48.764.13 31.482.13 14.2 13.822.19 30.713.19S61.297 14.2 61.297 31.482c0 17.282-13.693 31.292-30.584 31.292zm0-5.166c14.103 0 25.535-11.697 25.535-26.126 0-14.429-11.432-26.126-25.535-26.126-14.102 0-25.534 11.697-25.534 26.126 0 14.43 11.432 26.126 25.534 26.126z" />
                                        </g>
                                    </svg>
                                </div>

                                <p className={Styles.chooseBrokrTxt}>Over 2000+ mortgage brokers have partnered with HashChing. By providing your location we can quickly find you top-rated brokers <nobr>closest to you.</nobr></p>
                            </div>


                        </div>
                    </div>
                </section>
                {/* <div class="row">
                <div class="col-md-12">
                    <div style={{ margin: '20px auto', width: '300px', padding: '10px' }}>
                        <form>
                            <div class="form-group">
                                <label>Enter your email</label>
                                <input class="form-control" defaultValue={props.email} id="progress-email" onChange={props.handleClick} />
                                <div id="progress-email-error" class="error">

                                </div>
                            </div>
                            <div class="form-group" >
                                <a class="btn btn-primary" onClick={props.handleClick}>Save Progress</a>
                            </div>
                            <div class="form-group">
                                <a id="hash-btn-skip-progress" class="btn" onClick={props.handleClick}>Skip</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}

            </Fragment>
        )
    }
}



export default FunnelApp;
// export const pageQuery = graphql`
//   query {   
    
//   }
// `;
