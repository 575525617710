import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import Styles from '../../assets/styles/funnel.module.css'
import goldBrokerImage from '../../assets/images/gold-broker.png'
import goldIcon from "../../assets/images/gold-broker.png";
import premiumIcon from "../../assets/images/premium-broker.png";
import cx from 'classnames';
import locationImg from '../../assets/images/locationImg.png'
import brokerImage from '../../assets/images/broker-img.png'

class BrokerDetail extends Component {
    constructor() {
        super();
    }

    render() {
        console.log("inside book appointment ==>")
        console.log(this.props);

        
        
        if (!this.props.funnelData) {
            return (<div></div>)
        }
        
        let brokerId = this.props.funnelData.broker || ''
        console.log("====>broker" + brokerId);
        const data = this.props.data.allHashchingBroker.edges.filter((node) => {
            return node.node.broker_id === brokerId
        })

        console.log(data);

        if (!data.length) {
            return (<div></div>)
        }

        const brokerSingle = data[0].node

        console.log(brokerSingle);

        let premiumImg;
        if (brokerSingle.premium === 3) {
            premiumImg = <img
                src={goldIcon}
                alt="hashching-gold-broker"
                title="hashching-gold-broker"
            />
        }
        else if (brokerSingle.premium === 1) {
            premiumImg = <img
                src={premiumIcon}
                alt="hashching-premium-broker"
                title="hashching-premium-broker"
            />
        }

        // brokerImage = brokerSingle.profile_pic;

        return (
            <div className={cx('card', Styles.card)}>
                <div className={cx('card-body', Styles.cardBody)}>
                    <div className={cx('text-center', Styles.brokrDetailInnr)}>
                        <h4 className={cx(Styles.brokrPlace)}>Your broker advisor in <a >{`${brokerSingle.state}`}</a> </h4>
                        <img className={cx(Styles.brokrImg)} src={brokerSingle.profile_pic} alt="broker-img" />
                        <h4 className={cx(Styles.brokrNameStatus)}>
                            <span className={cx(Styles.brokrName)}>{`${brokerSingle.firstName} ${brokerSingle.lastName}`}</span>
                            <span className={cx(Styles.brokrstatus)}>{premiumImg}</span>
                        </h4>
                        <h4 className={cx(Styles.totalReviews)}>
                            <span className={cx(Styles.reviewRating)}>
                                <span className={cx('badge', Styles.badge)}>
                                    {/* <i className={cx('fa', Styles.faStar)}></i> */}
                                    <svg
                                        aria-hidden="true"
                                        data-prefix="fas"
                                        data-icon="star"
                                        className={Styles.svgInlineFa}
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                        />
                                    </svg>
                                    <span className={cx(Styles.ratingTxt)}>{brokerSingle.broker_rating}</span>
                                </span>
                            </span>
                            <span className={cx(Styles.countReviewsMain)}><span className={cx(Styles.countReviws)}>{brokerSingle.review_count}</span><span className={cx(Styles.reviwsTxt)}> Reviews</span></span>
                        </h4>
                        <h5 className={cx(Styles.brokrLocation)}>
                            {/* <i className={cx('fa', Styles.faMapaMrker)} aria-hidden="true"></i>  */}
                            <img src={locationImg} alt="location" />
                            <span className={Styles.locationTxt}>{`${brokerSingle.state} ${brokerSingle.postcode}`}</span></h5>
                        {/* <p className={cx(Styles.brokrSumry)}>“Helpful and very good <br /> customer service”</p> */}
                        {/* <p className={cx(Styles.somonelseText)}> <a className={cx(Styles.somonelseLink)} href="">Prefer someone else?</a></p> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default (props) => (
    <StaticQuery
        query={
            graphql`
        query {
           allHashchingBroker(sort:{
  fields: [sort_premium,tier_2,monthly_leads,review_count,broker_rating,deals_count],
  order:[DESC,DESC,ASC,DESC,DESC,DESC]
  })
          {
            edges
            {
              node
              {
                broker_id
                user_id
                firstName
                lastName
                profile_pic
                deals_count
                review_count
                broker_rating
                languages
                premium
                profile_view
                specifications
                postcode
                state
              }
            }
          }
      }
      `
        }
        render={(data) => (
            <BrokerDetail data={data} {...props} />
        )}
    />
)